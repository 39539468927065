import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import Header from '../CategoriesHeader';
import Footer from '../footer';
function NewBorn() {
  return (
    <div>
      <Header />
      <div className="jumbotron categoryHeadJumbo">
        <div className="row">
          <div
            className="col-md-6"
            id="category_ellipse"
            style={{
              backgroundImage: 'url(/ima/categories_ellipse_29.SVG)',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="card cardCategory" style={{ width: '18rem' }}>
              <h5 className="card-title cardCategoryTitle">NEW BORN</h5>
              <div className="card-body">
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
                <img src="/ima/purple-gen-btn.SVG" alt="logo" />
                <a href="/#" className="card-link">
                  {' '}
                  VIEW MESSAGES
                </a>
              </div>
            </div>
            <div className="category-col-img">
              <img src="/ima/category-small-ellipse.SVG" alt="logo" />
            </div>

            {/* <img src="/ima/categories_ellipse_29.SVG" id="category_ellipse" alt="categories_ellipse_29" />*/}
          </div>
          <div className="col-md-6" id="jumboCategorySecond">
            <div className="" id="background-pink"></div>
            <div className="">
              <img src="/ima/love-hand.PNG" id="love-hand" alt="logo" />
              <img
                src="/ima/missing_you.SVG"
                id="fcmb-two-love-birds"
                alt="logo"
              />
              <img src="/ima/plain_ellipse.SVG" id="plain_ellipse" alt="logo" />
              <img src="/ima/2_love_heart.SVG" id="twoLove_heart" alt="logo" />
              <img src="/ima/love-vector.SVG" id="love-vector" alt="logo" />
              <img src="/ima/love-image.SVG" id="love-image" alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <section id="tableSection">
        <table className="table" id="messageTable">
          <thead className="thead">
            <tr>
              <th scope="col" id="tableCategoryName">
                NEW BORN SMS
              </th>
              <th scope="col">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" href="/#" aria-label="Previous">
                        <span aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/#" aria-label="Next">
                        <span aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row" id="tableMsgRow">
                Hi Chuks, Tara loves you and wants all of us at FCMB to show you
                love, we...
              </td>

              <td id="tableActionRow">
                View message{' '}
                <FontAwesomeIcon
                  className="arrowIcon"
                  icon={faLongArrowAltRight}
                />
              </td>
            </tr>
            <tr>
              <td scope="row" id="tableMsgRow">
                Hi Chuks, love is in the air. Tara says… I love you and all of
                us at FCMB love...
              </td>
              <td id="tableActionRow">
                View message{' '}
                <FontAwesomeIcon
                  className="arrowIcon"
                  icon={faLongArrowAltRight}
                />
              </td>
            </tr>
            <tr>
              <td scope="row" id="tableMsgRow">
                Love is beautiful and even more so when you know you are loved.
                Tara lov...
              </td>
              <td id="tableActionRow">
                View message{' '}
                <FontAwesomeIcon
                  className="arrowIcon"
                  icon={faLongArrowAltRight}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <Footer />
    </div>
  );
}

export default NewBorn;
