import React, { useState, useEffect, useContext } from 'react';
import IsAccountOpen from './IsAccountOpen';
import NoAccount from './NoAccount';
import SendRecipientDetails from './SendRecipientDetails';
import IsAccountKYC from './IsAccountKYC';
import IsAccountDetails from './IsAccountDetails';
import MsgPreview from './MsgPreview';
import SendOTP from './SendOTP';
import { GlobalContext } from './../../Context/GlobalProvider';
import MessageService from '../../Services/message.services';

const OutputSteps = props => {
  const state = useContext(GlobalContext);

  function changeStep() {
    switch (state.step) {
      case 1:
        return (
          <IsAccountOpen
            backdrop="static"
            centered
            id="Info"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          />
        );
      case 2:
        return (
          <NoAccount
            backdrop="static"
            centered
            id="Info"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          />
        );
      case 5:
        return (
          <IsAccountKYC
            backdrop="static"
            centered
            id="Info"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          />
        );
      case 6:
        return (
          <IsAccountDetails
            backdrop="static"
            centered
            id="Info"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          />
        );
      case 7:
        return (
          <MsgPreview
            backdrop="static"
            centered
            id="Info"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          />
        );
      case 8:
        return (
          <SendOTP
            backdrop="static"
            centered
            id="Info"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          />
        );
      case 9:
        return (
          <SendRecipientDetails
            backdrop="static"
            centered
            id="Info"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          />
        );
      default:
        <IsAccountOpen
          backdrop="static"
          centered
          id="Info"
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        />;
    }
  }
  return <>{changeStep()}</>;
};

export default OutputSteps;
