import { useContext, useState } from 'react';
import { GlobalContext } from '../../Context/GlobalProvider';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import messageServices from '../../Services/message.services';

const initialMultipleRecipientData = {
  receiverUsername: '',
  emailAddress: '',
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const RecipientEmail = ({
  onNext,
  onBack,
  recipientData,
  setRecipientData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputData, setInputData] = useState({
    recipientName: '',
    recipientEmail: '',
  });

  const [otpSentMessage, fetchOtpSentMessage] = useState('');

  const [isVerified, setIsVerified] = useState(false);
  const [isOtpOpen, setIsOtpOpen] = useState(false);
  const [otpInput, setOtpInput] = useState('');

  const [singleRecipient, setSingleRecipient] = useState('');
  const [multipleRecipient, setMultipleRecipient] = useState('');
  const state = useContext(GlobalContext);

  const [fieldValidationError, setFieldValidationError] = useState({
    name: 'Reciever name is required',
    email: 'Enter a valid email address',
    show: false,
  });

  const [inputs, setInputs] = useState({
    accountNumber: '',
    remember: false,
  });

  const handleMultipleRecipientChange = (index, field, value) => {
    const data = [...recipientData];
    data[index] = {
      ...data[index],
      [field]: value,
    };
    setRecipientData(data);
  };

  const handleAddMore = () => {
    setRecipientData([...recipientData, initialMultipleRecipientData]);
  };

  const handleRemoveRecipient = index => {
    setRecipientData(prevData => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
  };

  const controller = e => {
    e.preventDefault();

    if (inputData?.recipientName === '') {
      state.notifyServerGlobal('Please input your recipient name or nickname');
      return;
    }

    if (inputData?.recipientEmail === '') {
      state.notifyServerGlobal('Please input your recipient email');
      return;
    } else {
      onNext();
    }

    state.notifyServerGlobal('');
    state.userInfo({
      recipientName: inputData?.recipientName,
      recipientEmail: inputData?.recipientEmail,
    });
  };

  //handle change function for the checkboxes
  const handleRadioChange = value => {
    setRecipientData(prev => {
      return prev.map(data => {
        return { ...data, receiverUsername: '' };
      });
    });
    if (value === 'single_recipient') {
      setSingleRecipient(value);
      //reset the single recipient state
      setMultipleRecipient('');
    } else if (value === 'multiple_recipient') {
      setMultipleRecipient(value);
      //reset the multiple recipient state
      setSingleRecipient('');
    }
  };

  const handleOtpChange = e => {
    if (Number(e.target.value) && e.target.value !== ' ') {
      setOtpInput(e.target.value.trim());
    }
    if (e.target.value === '' && Number(e.target.value) === 0) {
      setOtpInput(e.target.value.trim());
    }
  };

  const handleRecipientChange = e => {
    setInputData({ ...inputData, recipientName: e.target.value.trim() });
  };

  const handleRecipientEmailChange = e => {
    setInputData({ ...inputData, recipientEmail: e.target.value.trim() });
  };

  const handleChange = e => {
    switch (e.target.type) {
      case 'checkbox':
        if (e.target.checked) {
          //console.log(e.target.checked)
          setInputs({ ...inputs, remember: true });
        } else {
          setInputs({ ...inputs, remember: false });
        }
        break;
      case 'text':
        if (Number(e.target.value) && e.target.value !== ' ') {
          setInputs({ ...inputs, accountNumber: e.target.value.trim() });
        }
        if (e.target.value === '' && Number(e.target.value) === 0) {
          setInputs({ ...inputs, accountNumber: e.target.value.trim() });
        } else {
          return null;
        }
        break;
      default:
        break;
    }
  };

  const handleController = () => {
    if (inputs.accountNumber === '') {
      state.notifyServerGlobal('Fields cannot be empty');
      return;
    }

    if (inputs.accountNumber.length < 10 || inputs.accountNumber.length > 10) {
      state.notifyServerGlobal('Account number should be 10 digits');
      return;
    }
    state.notifyServerGlobal('');
    // state.modalState(true)
    // state.modalStep(num)
    // state.userInfo({ ...inputs });
    // state.user.accountNumber(inputs?.accountNumber)
    state.userInfo({
      accountNumber: inputs?.accountNumber,
      recipientName: inputData?.recipientName,
      recipientPhoneNumber: inputData?.recipientPhoneNumber,
      recipientEmail: inputData?.recipientEmail,
    });
    // console.log(state);
    // setIsOtpOpen(prev => !prev);
    // call onnext after otp

    onNext();
  };

  const resendOpt = () => {
    state.notifyServerGlobal('');
    messageServices
      .SendOtpToSender(state.messageHistoryId, state.user.accountNumber)
      .then(
        response => {
          fetchOtpSentMessage(response.data.description);
          //console.log("state.messageId@preview",response.data.description);
          //console.log("Otp response",otpSentMessage.message)
          state.notifyServerGlobal(response.data.description + ' again');
          //console.log("state.messpreviw-modeld",state)
          //console.log("otpSentMessage .msgpreview-modeld",otpSentMessage)
        },
        error => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          fetchOtpSentMessage(_content);
          state.notifyServerGlobal(_content.data.description);
        },
      )
      .catch(e => {
        //console.log(e);
      });
  };

  const handleSubmitSingle = e => {
    e.preventDefault();
    if (inputData.recipientName && emailRegex.test(inputData.recipientEmail)) {
      setIsModalOpen(prev => !prev);
      setFieldValidationError(prev => ({ ...prev, show: false }));
    }
    setFieldValidationError(prev => ({ ...prev, show: true }));
  };

  const handleSubmitMultiple = e => {
    e.preventDefault();
    let isValid = true;

    recipientData.forEach(recipient => {
      if (!recipient?.receiverUsername) {
        setFieldValidationError(prev => ({
          ...prev,
          name: `Recipient name is required`,
          show: true,
        }));
        isValid = false;
        return;
      }

      if (!emailRegex.test(recipient?.emailAddress)) {
        setFieldValidationError(prev => ({
          ...prev,
          email: `Enter a valid email address`,
          show: true,
        }));
        isValid = false;
        return;
      }
    });

    if (isValid) {
      setIsModalOpen(prev => !prev);
      setFieldValidationError(prev => ({ ...prev, show: false }));
    }
  };

  return (
    <>
      <div className="recipient_details">
        <p className="details_p">Please add recipients details below</p>

        <div className="check_boxes">
          <div
            style={{ cursor: 'pointer' }}
            className="single_checkbox_recipient"
            onClick={() => handleRadioChange('single_recipient')}
          >
            <input
              type="radio"
              name="single_recipient"
              value="single_recipient"
              checked={singleRecipient === 'single_recipient'}
              style={{ marginRight: '1rem' }}
            />
            <div>
              <label htmlFor="single_recipient" style={{ cursor: 'pointer' }}>
                Single Recipient
              </label>
              <p
                style={{
                  width: '100%',
                  maxWidth: '273px',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#4B5563',
                }}
              >
                Select if you are sending to only one recipient
              </p>
            </div>
          </div>
          <div
            onClick={() => handleRadioChange('multiple_recipient')}
            className="multiple_checkbox_recipient"
          >
            <input
              type="radio"
              name="multiple_recipient"
              value="multiple_recipient"
              checked={multipleRecipient === 'multiple_recipient'}
              style={{ marginRight: '1rem' }}
            />

            <div style={{ cursor: 'pointer' }}>
              <label htmlFor="single_recipient" style={{ cursor: 'pointer' }}>
                Multiple Recipients
              </label>
              <p
                style={{
                  width: '100%',
                  maxWidth: '273px',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#4B5563',
                }}
              >
                Select if you are sending to two or more recipients
              </p>
            </div>
          </div>
        </div>

        {/* conditional rendering of the form based on the checkbox */}
        {singleRecipient === 'single_recipient' && (
          <form className="recipient-form pr-4" onSubmit={controller}>
            <div style={{ gap: '10px' }} className="d-flex flex-column mt-2">
              <div>
                <label className="details_label" htmlFor="name">
                  Recipient name or nickname
                </label>
                <input
                  value={inputData?.recipientName}
                  className="details_input"
                  type="text"
                  onChange={e => handleRecipientChange(e)}
                  placeholder="Enter name"
                />
                {!inputData?.recipientName && fieldValidationError?.show && (
                  <div style={{ color: 'red', marginTop: '10px' }}>
                    {fieldValidationError?.name}
                  </div>
                )}
              </div>

              <div>
                <label className="details_label" htmlFor="medium">
                  Recipient Email
                </label>
                <input
                  className="details_input"
                  type="text"
                  value={inputData?.recipientEmail}
                  onChange={e => handleRecipientEmailChange(e)}
                  placeholder="Enter email"
                />
                {!emailRegex.test(inputData?.recipientEmail) &&
                  fieldValidationError?.show && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                      {fieldValidationError?.email}
                    </div>
                  )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '1rem 0',
              }}
            >
              <div className="cursor-pointer">
                <p className="back_text" onClick={onBack}>
                  BACK
                </p>
              </div>
              <button
                className="details_button"
                type="button"
                onClick={e => handleSubmitSingle(e)}
              >
                Next
              </button>
            </div>
          </form>
        )}

        {multipleRecipient === 'multiple_recipient' && (
          <form className="recipient-form" onSubmit={controller}>
            {recipientData?.map((recipient, index) => (
              <div
                key={index}
                style={{ marginTop: '10px', gap: '15px' }}
                className={`d-flex flex-column ${index === 0 && 'mr-4'}`}
              >
                <div style={{ gap: '5px' }} className="d-flex gap-2">
                  <div className="flex-grow-1 w-full">
                    <label className="details_label" htmlFor={`name-${index}`}>
                      Recipient name or nickname
                    </label>
                    <input
                      value={recipient?.receiverUsername}
                      className="details_input"
                      type="text"
                      onChange={e =>
                        handleMultipleRecipientChange(
                          index,
                          'receiverUsername',
                          e?.target?.value,
                        )
                      }
                      placeholder="Input name"
                    />
                    {!recipient?.receiverUsername &&
                      fieldValidationError?.show && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {fieldValidationError?.name}
                        </div>
                      )}
                  </div>
                  <div className="flex-grow-1">
                    <label className="details_label" htmlFor={`email-${index}`}>
                      Recipient email
                    </label>
                    <input
                      value={recipient?.emailAddress}
                      className="details_input"
                      type="email"
                      onChange={e =>
                        handleMultipleRecipientChange(
                          index,
                          'emailAddress',
                          e?.target?.value,
                        )
                      }
                      placeholder="Enter email address"
                    />
                    {!emailRegex.test(recipient?.emailAddress) &&
                      fieldValidationError?.show && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {fieldValidationError?.email}
                        </div>
                      )}
                  </div>
                  {index !== 0 && (
                    <button
                      type="button"
                      className="close mt-4 ml-2"
                      style={{ color: 'red' }}
                      aria-label="Close"
                      onClick={() => handleRemoveRecipient(index)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  )}
                </div>
              </div>
            ))}

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px',
                margin: '20px 0px',
                paddingRight: '20px',
              }}
            >
              <button
                type="button"
                className="add_more_button"
                onClick={() => handleAddMore()}
              >
                ADD MORE
              </button>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <p className="back_text" onClick={onBack}>
                  BACK
                </p>
                <button
                  // onClick={e => {
                  //   e.preventDefault();
                  //   setIsModalOpen(prev => !prev);
                  // }}
                  onClick={e => handleSubmitMultiple(e)}
                  // type="submit"
                  className="details_button"
                >
                  NEXT
                </button>
              </div>
            </div>
          </form>
        )}
      </div>

      {isModalOpen && (
        <div>
          <Modal
            show={isModalOpen}
            // onHide={()=>do something}
            backdrop="static"
            keyboard={false}
            id="modal-wrapper"
          >
            <div id="gradient-modal-bar"></div>
            <Modal.Header
              className="rounded-0 mt-2"
              closeButton
              onClick={() => setIsModalOpen(prev => !prev)}
            ></Modal.Header>
            <Modal.Body>
              <div className="modal-body">
                <div className="form-fields">
                  <h5>Please provide your account number</h5>
                  <em className="text-danger">{state.notifyServer}</em>
                  <div className="kyc-input-wrapper mb-2">
                    <input
                      placeholder="Account Number"
                      value={inputs.accountNumber}
                      onChange={e => handleChange(e)}
                      type="text"
                      maxLength={10}
                      name="accountNumber"
                    />
                  </div>
                  <div className="kyc-input-wrapper check-wrapper mb-2">
                    <input
                      type="checkbox"
                      name="remember"
                      onChange={e => handleChange(e)}
                      checked={inputs.remember}
                    />
                    <span className="checkbox-cover">
                      <FontAwesomeIcon size="xs" icon={faCheck} />
                    </span>
                    <label className="pt-2">Save details for next time</label>
                  </div>
                </div>
                <div className="">
                  <Button
                    variant="secondary"
                    id="accSecondaryBtn"
                    type="button"
                    onClick={() => setIsModalOpen(prev => !prev)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="primary"
                    id="accPrimaryBtn"
                    type="button"
                    onClick={() => handleController()}
                    //  onClick={onNext}
                  >
                    PROCEED
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}

      {isOtpOpen && (
        <div>
          <Modal
            show={isOtpOpen}
            // onHide={()=>do something}
            backdrop="static"
            keyboard={false}
            id="modal-wrapper"
          >
            <div id="gradient-modal-bar"></div>
            <Modal.Header
              className="rounded-0 mt-2"
              closeButton
              onClick={() => setIsOtpOpen(prev => !prev)}
            ></Modal.Header>
            <Modal.Body>
              <div className="modal-body">
                <div className="form-fields">
                  <h5>Please enter OTP sent to your phone number</h5>
                  <h6 className="text-danger">{state.notifyServer}</h6>
                  <div className="kyc-input-wrapper mb-2">
                    <input
                      placeholder="Input OTP here"
                      value={otpInput}
                      onChange={e => handleOtpChange(e)}
                      type="text"
                      maxLength={10}
                      name="otp"
                    />
                  </div>
                  <div className="kyc-input-wrapper check-wrapper mb-2">
                    <label className="pt-2">
                      Didn't get OTP ?{' '}
                      <Button
                        style={{ marginBottom: '4px' }}
                        variant="link"
                        onClick={() => resendOpt()}
                        type="button"
                      >
                        Resend
                      </Button>
                    </label>
                  </div>
                </div>
                <div className="">
                  <Button
                    variant="secondary"
                    id="accSecondaryBtn"
                    type="button"
                    onClick={() => setIsOtpOpen(prev => !prev)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="primary"
                    id="accPrimaryBtn"
                    onClick={() => {
                      // setIsVerified(prev => !prev);
                      onNext();
                    }}
                  >
                    PROCEED
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};
