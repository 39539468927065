import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import MenuService from '../Services/menu.service'
import Spinner from 'react-bootstrap/Spinner'


function CategoriesHeader() {
    const [menus, fetchMenu] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getMenu = () => {
            MenuService.getMenuCategories().then(response => {
                fetchMenu(response.data.categories)
                //console.log(" fetchMenu(response.data.categories)", response.data.categories)
            }, (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                fetchMenu(_content);
            }
            ).catch(e => {
                //console.log(e);
            });
        };
        getMenu();
    }, []);


    return (
        <>
            <Navbar bg="navbar-light" className="navbar navbar-expand-lg navbar-light bg-white" expand="lg" id="header">
            <div className="container">
                <Navbar.Brand href="/home"><img src="/ima/fcmb_logo.SVG" id="logo" alt="logo" /></Navbar.Brand>
                <Navbar.Brand href="/home"><img src="/ima/shoutout_category.SVG" id="shout-logo" alt="logo" /></Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {
                        menus?.length > 0 && (
                            <Nav className="mr-auto" id="navbarItemsContent">
                                <Nav.Link className="nav-item categoryHeader" href="/home" id="nav-items">HOME</Nav.Link>
                                <Nav.Link className="nav-item categoryHeader" href="/love" id="nav-items">{menus[8].categoryName}</Nav.Link>
                                <Nav.Link className="nav-item categoryHeader" href="/anniversary" id="nav-items">{menus[2].categoryName}</Nav.Link>
                                <Nav.Link className="nav-item categoryHeader" href="/congratulations" id="nav-items">{menus[0].categoryName}</Nav.Link>

                                {/*
                                <NavDropdown className="nav-item categoryHeader" title={menus[0].categoryName} id="basic-nav-dropdown">
                                    <NavDropdown.Item className="nav-item categoryHeader" id="dropdownItem" href="/congratulations">{menus[0].categoryName}</NavDropdown.Item>

                                    <NavDropdown.Item className="nav-item categoryHeader" id="dropdownItem" href="/newborn">NEW BORN</NavDropdown.Item>
                                    <NavDropdown.Item className="nav-item categoryHeader" id="dropdownItem" href="/newjob">NEW JOB</NavDropdown.Item>
                                    <NavDropdown.Item className="nav-item categoryHeader" className="nav-item categoryHeader" id="dropdownItem" href="/houseopening">HOUSE OPENING</NavDropdown.Item>
                                    <NavDropdown.Item id="dropdownItem" href="/promotion">PROMOTION</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item className="nav-item categoryHeader" id="dropdownItem" className="itemDivided" href="#action/3.4">VIEW ALL</NavDropdown.Item>
                                </NavDropdown>
*/}
                                <Nav.Link className="nav-item categoryHeader" href="/birthday" id="nav-items">{menus[3].categoryName}</Nav.Link>
                                <Nav.Link className="nav-item categoryHeader" href="/missing-you" id="nav-items">{menus[4].categoryName}</Nav.Link>
                                <Nav.Link className="nav-item categoryHeader" href="/quick-recovery" id="nav-items">{menus[5].categoryName}</Nav.Link>

                                <NavDropdown className="nav-item categoryHeader" title="MORE" id="basic-nav-dropdown">
                                    <NavDropdown.Item className="nav-item categoryHeader" id="dropdownItem" href="/apology">{menus[6].categoryName}</NavDropdown.Item>
                                    <NavDropdown.Item className="nav-item categoryHeader" id="dropdownItem" href="/loss">{menus[7].categoryName}</NavDropdown.Item>
                                    {/*  <NavDropdown.Divider />
                                    <NavDropdown.Item id="dropdownItem" className="itemDivided" href="#action/3.4">Separated link</NavDropdown.Item>
                                  */}
                                </NavDropdown>
                            </Nav>

                        )
                    }
                </Navbar.Collapse>
                </div>
            </Navbar>
        </>
    );
}

export default CategoriesHeader;