import { useHistory } from 'react-router-dom';
import './MessagesSent.css';

export const MessagesSent = () => {
  let history = useHistory();

  return (
    <div className="messages_sent_body">
      <div className="inner_body">
        <div className="img_width">
          <img src="/assets/svgs/sending.svg" alt="Success" />
        </div>

        <div className="success_box">
          <p className="success_text">Succesful!!</p>
          <p className="success_message">
            Congratulations! Your account has been confirmed and messages have
            been successfully sent to the recipients.
          </p>

          <div className="go_home_box">
            <button
              className="details_button"
              onClick={() => {
                localStorage.removeItem('appState');
                history.push('/home');
              }}
            >
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
