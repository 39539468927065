import messageServices from './message.services';
import { useState } from 'react';

export const useSendOtp = () => {
  const [data, setData] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const triggerSendOtp = async (messageHistoryId, senderAccountNumber) => {
    try {
      setLoading(prev => !prev);
      const res = await messageServices.SendOtpToSender(
        messageHistoryId,
        senderAccountNumber,
      );
      setLoading(prev => !prev);
      if (res?.data?.responseData?.response) {
        setError('');
        setData(res?.data?.description || 'OTP Sent successfully');
        return;
      }
      setData('');
      setError(res?.data?.description || 'An error occurred while sending OTP');
    } catch (error) {
      setLoading(prev => !prev);
      setData('');
      setError(error?.message || 'An error occurred while sending OTP');
    }
  };

  return {
    error,
    loading,
    data,
    triggerSendOtp,
  };
};
