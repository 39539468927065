import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const VerifyOtp = ({
  isOtpOpen,
  setIsOtpOpen,
  state,
  otpInput,
  handleOtpChange,
  triggerResendOtp,
  sendMessageData,
  sendMessageError,
  sendMessageIsLoading,
  handleSendMessage,
  sendOtpData,
}) => {
  const [time, setTime] = useState({ seconds: 0, minutes: 2 });

  const reset = () => {
    setTime(time => ({ ...time, minutes: 2, seconds: 0 }));
  };

  const handleResetOtp = async () => {
    if (sendOtpData) {
      reset();
    }
  };

  useEffect(() => {
    let timer;
    timer = setInterval(() => {
      setTime(time => ({ ...time, seconds: time.seconds - 1 }));
      if (time.seconds === 0) {
        setTime(time => ({
          ...time,
          minutes: time.minutes - 1,
          seconds: 59,
        }));
      }
      if (time.minutes === 0 && time.seconds === 0) {
        setTime(time => ({ ...time, minutes: 0, seconds: 0 }));
        return;
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  const handleResendOtp = () => {
    triggerResendOtp();
    handleResetOtp();
  };

  return (
    <>
      <Modal
        show={isOtpOpen}
        backdrop="static"
        keyboard={false}
        id="modal-wrapper"
      >
        <div id="gradient-modal-bar"></div>
        <Modal.Header
          className="rounded-0 mt-2"
          closeButton
          onClick={() => setIsOtpOpen(prev => !prev)}
        ></Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="form-fields">
              <h5>Please enter OTP sent to your phone number </h5>
              <h6 className="text-danger">{state.notifyServer}</h6>
              <div className="kyc-input-wrapper mb-2">
                <input
                  placeholder="Input OTP here"
                  value={otpInput}
                  onChange={e => handleOtpChange(e)}
                  type="text"
                  maxLength={10}
                  name="otp"
                />
              </div>
              <div className="kyc-input-wrapper check-wrapper mb-2">
                <label className="pt-2">
                  <span className="font-bold d-block cursor-pointer">
                    {time.seconds <= 0 && time.minutes <= 0 ? (
                      <>
                        {' '}
                        Didn't get OTP?{' '}
                        <Button
                          style={{ marginBottom: '4px' }}
                          variant="link"
                          type="button"
                          onClick={handleResendOtp}
                        >
                          Resend
                        </Button>
                      </>
                    ) : (
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <p>Code expires in: </p>
                        <span className="text-danger text-error-color">
                          {time.minutes < 10
                            ? '0' + time.minutes
                            : time.minutes}
                          :
                          {time.seconds < 10
                            ? '0' + time.seconds
                            : time.seconds}
                        </span>
                      </div>
                    )}
                  </span>
                </label>
              </div>
              {sendMessageData && (
                <div class="alert alert-success" role="alert">
                  {sendMessageData}, Kindly Proceed
                </div>
              )}

              {sendMessageError && (
                <div class="alert alert-danger" role="alert">
                  {sendMessageError}
                </div>
              )}
            </div>

            <div className="">
              <Button
                variant="secondary"
                id="accSecondaryBtn"
                onClick={() => setIsOtpOpen(prev => !prev)}
              >
                CANCEL
              </Button>
              <Button
                variant="primary"
                id="accPrimaryBtn"
                disabled={sendMessageIsLoading}
                // onClick={() => setIsVerified(prev => !prev)}
                onClick={handleSendMessage}
              >
                {sendMessageIsLoading ? 'Loading...' : 'PROCEED'}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerifyOtp;
