import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Home from './components/Home';
import Anniversary from './components/Categories/Anniversary';
import Apology from './components/Categories/Apology';
import Birthday from './components/Categories/Birthday';
import Congratulations from './components/Categories/Congratulations';
import HouseOpening from './components/Categories/HouseOpening';
import Loss from './components/Categories/Loss';
import Love from './components/Categories/Love';
import MissingYou from './components/Categories/MissingYou';
import NewBorn from './components/Categories/NewBorn';
import NewJob from './components/Categories/NewJob';
import Promotion from './components/Categories/Promotion';
import QuickRecovery from './components/Categories/QuickRecovery';
import { UserDetails } from './pages/userDetails/UserDetails';
import { Messages } from './pages/messages/Messages';
import { Recipients } from './pages/recipients/Recipients';
import { DynamicRoute } from './components/dynamicRoute/DynamicRoute';
import { MessagesSent } from './pages/messagesSent/MessagesSent';
import { EmailMessages } from './pages/messages/EmailMessages';
import { RecipientEmail } from './pages/recipients/RecipientEmail';

function App() {
  //{ process.env.NODE_ENV = 'development' ? process.env.dev : process.env.prod }

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path={['/', '/home']} component={Home} />
          <Route exact path="/love" component={Love} />
          <Route exact path="/anniversary" component={Anniversary} />
          <Route exact path="/apology" component={Apology} />
          <Route exact path="/houseopening" component={HouseOpening} />
          <Route exact path="/loss" component={Loss} />
          <Route exact path="/newborn" component={NewBorn} />
          <Route exact path="/newjob" component={NewJob} />
          <Route exact path="/promotion" component={Promotion} />
          <Route exact path="/congratulations" component={Congratulations} />
          <Route exact path="/birthday" component={Birthday} />
          <Route exact path="/missing-you" component={MissingYou} />
          <Route exact path="/quick-recovery" component={QuickRecovery} />
          <Route exact path="/userdetails" component={UserDetails} />
          <Route exact path="/messages" component={Messages} />
          <Route exact path="/emailType" component={EmailMessages} />
          <Route exact path="/recipients" component={Recipients} />
          <Route exact path="/recipientsemail" component={RecipientEmail} />
          <Route exact path="/messagessent" component={MessagesSent} />
          <Route exact path="/send-shoutout" component={DynamicRoute} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
