import React, { useState, useContext } from 'react';
import Header from './header';
import OutputSteps from './Modals/OutputSteps';
import { GlobalContext } from './../Context/GlobalProvider';

function HeroSlider(props) {
  // state from context api
  const state = useContext(GlobalContext);
  const controller = () => {
    state.modalState(true);
    state.modalStep(1);
  };
  return (
    <header
      id="heroSlider"
      style={{
        backgroundImage: 'url(/ima/header-hero-bg.SVG)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div style={{ width: '100%' }} className="container">
        <Header />
        <div className="jumbotron2" id="jumbotron2">
          <div className="row" id="middle-info">
            <div
              className="col-md-4"
              style={{
                height: '476px',
                width: '480px',
                paddingRight: '20px',
                marginRight: '100px',
                marginLeft: '-20px',
              }}
            >
              <img src="/ima/shout-circle-img.SVG" alt="shout-circle" />
            </div>
            <div
              className="col-md-6"
              id="jumboSecond"
              style={{ marginLeft: '100px', marginTop: '60px' }}
            >
              <div id="what_is_fcmb_shout_out">What is FCMB Shout-Out?</div>

              <div id="fcmb_shout_out_description">
                The FCMB Shout-out is a service that will enable customers send
                'wishes' or short messages in form of SMS like good will,
                anniversary, birthday, congratulatory and other emotional
                messages to family members, friends and loved ones.
              </div>
              {/*<button type="button" className="btn btn-primary" id="shoutBtn" onClick={props.buttonClicked}><span>Get Started</span><img src="/ima/btn-ellipse.SVG" id="shout-circle" alt="shout-circle" /></button>*/}

              <a href="#shoutOutGrid">
                <img
                  id="purple-gen-HomeBtn"
                  src="/ima/purple-gen-HomeBtn.png"
                  alt="shout-circle"
                  style={{ cursor: 'pointer' }}
                />
                <a href="#shoutOutGrid" className="card-link" id="homeShoutBtn">
                  GET STARTED
                </a>
              </a>
            </div>
          </div>
        </div>
      </div>
      {state.showModal ? <OutputSteps show={state.showModal} /> : null}
    </header>
  );
}
export default HeroSlider;
