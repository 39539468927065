import messageServices from './message.services';
import { useState } from 'react';

export const useValidateOTPAndSendSms = () => {
  const [data, setData] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const triggerSendMessage = async otp => {
    try {
      setLoading(prev => !prev);
      const res = await messageServices.ValidateOtpAndSendMessage(otp);
      setLoading(prev => !prev);
      if (res?.data?.responseData?.response) {
        setError('');
        setData(res?.data?.description || 'OTP Sent successfully');
        return;
      }
      setData('');
      setError(
        res?.data?.description || 'An error occurred while validating OTP',
      );
    } catch (error) {
      setLoading(prev => !prev);
      setData('');
      setError('An error occurred while validating OTP');
    }
  };

  return {
    error,
    loading,
    data,
    triggerSendMessage,
  };
};
