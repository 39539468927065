import React, { useState,useContext } from "react";
import ReactDOM from "react-dom";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form"
import {GlobalContext} from "./../../Context/GlobalProvider"


const IsAccountOpen = (props) => {

    // context api
    const state = useContext(GlobalContext)  

    const [inputs, setInputs] = useState({preferredName:'',recipientName:'', recipientPhone:'', recipientEmail:''})

    const controller =(num)=>{
      //validate
      if(inputs.preferredName=== "" || inputs.recipientName=== "" || inputs.recipientPhone === "" || inputs.recipientEmail === ""){
        state.notifyServerGlobal("Fields cannot be empty")
        return
      }   
        state.notifyServerGlobal("")
        state.modalState(true)
        state.modalStep(num)
        state.userInfo({...inputs})
    }
    const closeModal=()=>{
        state.modalState(false)
      state.modalStep(1)

    }



const handleChange=(e)=>{
    switch (e.target.name) {
      case "preferredName":
        setInputs({...inputs,preferredName:e.target.value})
        break;
      case "recipientName":
        setInputs({...inputs,recipientName:e.target.value})
        break;
      case "recipientPhone":
        setInputs(({...inputs,recipientPhone:e.target.value.trim()}))
        break;
      case "recipientEmail":
        setInputs({...inputs,recipientEmail:e.target.value.trim()})    
        /*
        if((e.target.value) && e.target.value!==" "){
            setInputs(({...inputs,recipientPhone:e.target.value.trim()}))
          }
          if(e.target.value==="" && Number(e.target.value)===0 ){
            setInputs(({...inputs,recipientPhone:e.target.value.trim()}))
          }
         else{
            return null
          }
        */
        break;
      default:
        break;
    }
  }
  
  return ReactDOM.createPortal(
    <div>
      <Modal
        show={state.showModal}
        // onHide={()=>do something}
        backdrop={props.backdrop}
        keyboard={false}
        id="modal-wrapper"
      >
        <div id="gradient-modal-bar"></div>
        <Modal.Header
          className="rounded-0 mt-2"
          closeButton
          onClick={() => closeModal()}
        ></Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="form-fields">
              <h5>Please provide the following details</h5>
              <em className="text-danger">{state.notifyServer}</em><br></br>
              <Form.Label size="sm">Your preferred name or nickname</Form.Label>
              <div className="kyc-input-wrapper mb-2">
                <input placeholder="" 
                 value={inputs.preferredName} 
                 name="preferredName"
                 onChange={(e)=>handleChange(e)}
                 maxLength={40}
                 />
              </div>
              <Form.Label>Recipient name or nickname</Form.Label>

              <div className="kyc-input-wrapper mb-2">
                <input 
                placeholder=""
                name="recipientName"
                inputs={inputs.recipientName}
                onChange={(e)=>handleChange(e)}
                maxLength={40}
                />
              </div>
              <Form.Label>Recipient phone number</Form.Label>
              <div className="kyc-input-wrapper mb-4">
                <input 
                placeholder="" 
                name="recipientPhone"
                value={inputs.recipientPhone}
                onChange={(e)=>handleChange(e)}
                maxLength={15}
                />
              </div>
              <Form.Label>Recipient email address</Form.Label>
              <div className="kyc-input-wrapper mb-4">
                <input 
                placeholder=""
                name="recipientEmail"
                value={inputs.recipientEmail}
                onChange={(e)=>handleChange(e)}
                // maxLength={40}
                />
              </div>
            </div>
            <div className="">
              <Button
                variant="secondary"
                id="accSecondaryBtn"
                onClick={() => closeModal()}
              >
                CANCEL
              </Button>
              <Button 
              variant="primary" 
              id="accPrimaryBtn"
                onClick={() => controller(6)}
              >
                NEXT
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>,
    document.getElementById("modal-root")
  );
};

export default IsAccountOpen;
