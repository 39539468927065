import React, { useState, useContext } from "react";
import ReactDOM from "react-dom";
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import { GlobalContext } from "./../../Context/GlobalProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";


const NoAccount = (props) => {

    // context api
    const state = useContext(GlobalContext)
    const controller = (num) => {
        state.modalState(true)
        state.modalStep(num)
    }
    const closeModal = () => {
        state.modalState(false)
    }
    return ReactDOM.createPortal(
        <div >
            <Modal show={state.showModal}
                // onHide={()=>do something} 
                backdrop="static" keyboard={false} id="isAccountOpenModal">
                <div id="isAccountOpenModal">
                </div>
                <Modal.Header className="rounded-0 mt-2" closeButton onClick={() => closeModal()}>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4">
                            <img src="/ima/noaccount_heartbroken.SVG" id="isAccImg" alt="isAccountOpen" />
                        </div>
                        <div className="col-md-8" style={{ padding: "25px 25px 25px 25px" }} >
                            <div className="isAccountOpenHeaderCol8 no-account-desc">
                                <h5>Ooops! this service is only available to FCMB customers.</h5>
                                <p>Don't have an account ? Open one now - it takes 5 minutes and you get your account number instantly.</p>
                                <div>
                                    <FontAwesomeIcon icon={faLink} /> <a href="https://onlineaccount.fcmb.com">https://onlineaccount.fcmb.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </div>, document.getElementById("modal-root")
    )

};

export default NoAccount