import axios from 'axios';

const API_URL = process.env.REACT_APP_ApiBaseUrl;
const Token = process.env.REACT_APP_Auth_Token;

//const API_URL = "http://172.27.9.82:8090/api/ShoutOutMessage?catId=10012&platId=7";
//const Token = "RmNtYlNob3V0T3V0OndlbGNvbWVAMTIz";

const getAnniversaryMessages = () => {
  //const response = axios.get(API_URL + "GetAllCategories", { headers: Token });
  return axios.get(API_URL + 'ShoutOutMessage?catId=10008&platId=7', {
    headers: {
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

const getApologyMessages = () => {
  //const response = axios.get(API_URL + "GetAllCategories", { headers: Token });
  return axios.get(API_URL + 'ShoutOutMessage?catId=10012&platId=7', {
    headers: {
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

const getBirthdayMessages = () => {
  //const response = axios.get(API_URL + "GetAllCategories", { headers: Token });
  return axios.get(API_URL + 'ShoutOutMessage?catId=10009&platId=7', {
    headers: {
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

const getCongratulationMessages = () => {
  //const response = axios.get(API_URL + "GetAllCategories", { headers: Token });
  return axios.get(API_URL + 'ShoutOutMessage?catId=10006&platId=7', {
    headers: {
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

const getLossMessages = () => {
  //const response = axios.get(API_URL + "GetAllCategories", { headers: Token });
  return axios.get(API_URL + 'ShoutOutMessage?catId=10013&platId=7', {
    headers: {
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

const getLoveMessages = () => {
  //const response = axios.get(API_URL + "GetAllCategories", { headers: Token });
  return axios.get(API_URL + 'ShoutOutMessage?catId=10014&platId=7', {
    headers: {
      ApiKey: `${Token}`,
    },
  });
};

const getMissingYouMessages = () => {
  //const response = axios.get(API_URL + "GetAllCategories", { headers: Token });
  return axios.get(API_URL + 'ShoutOutMessage?catId=10010&platId=7', {
    headers: {
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

const getQuickRecoveryMessages = () => {
  //const response = axios.get(API_URL + "GetAllCategories", { headers: Token });
  return axios.get(API_URL + 'ShoutOutMessage?catId=10011&platId=7', {
    headers: {
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

const SendDataToServer = data => {
  return axios.post(API_URL + 'FCMBShoutOut', data, {
    headers: {
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

const PreviewMessages = data => {
  return axios.post(API_URL + 'FCMBShoutOut', data, {
    headers: {
      'Content-Type': 'application/json',
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

const MakePaymentForTheMessages = data => {
  //const response = axios.get(API_URL + "GetAllCategories", { headers: Token });
  return axios.post(API_URL + 'FCMBShoutOut', data, {
    headers: {
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

const SendOtpToSender = (MessageHistoryId, SenderAccountNumber) => {
  return axios.post(
    API_URL + `Otp`,
    {
      senderAccountNumber: SenderAccountNumber,
      messageHistoryIds: MessageHistoryId,
    },
    {
      headers: {
        ApiKey: `${Token}`,
        'Cross-Origin-Opener-Policy': 'same-origin',
        'X-Frame-Options': 'DENY',
        'X-Content-Type-Options': 'nosniff',
      },
    },
  );
};

//Otp/ValidateOptAndSendSMS?Otp=9449
const ValidateOtpAndSendMessage = otp => {
  return axios.get(API_URL + `Otp/ValidateOptAndSendSMS?Otp=${otp}`, {
    headers: {
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

export default {
  getLoveMessages,
  getAnniversaryMessages,
  getApologyMessages,
  getBirthdayMessages,
  getCongratulationMessages,
  getLossMessages,
  getMissingYouMessages,
  getQuickRecoveryMessages,
  PreviewMessages,
  MakePaymentForTheMessages,
  SendOtpToSender,
  ValidateOtpAndSendMessage,
};
