const Connector = props => {
  return (
    <svg
      width={2}
      height={20}
      viewBox="0 0 2 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="2" height="20" rx="1" fill="#5C068C" />
      <rect width="2" height="20" rx="1" fill="white" fillOpacity="0.3" />
    </svg>
  );
};

export default Connector;
