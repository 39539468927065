export const images = [
  {
    categoryName: 'BIRTHDAY',
    images: [
      {
        label: 'Theme 1',
        path: '/assets/themes/birthday/FCMB_Shout-Out_Birthday_1.jpg',
      },
      {
        label: 'Theme 2',
        path: '/assets/themes/birthday/FCMB_Shout-Out_Birthday-2.jpg',
      },
    ],
  },
  {
    categoryName: 'CONGRATULATIONS',
    images: [
      {
        label: 'Theme 1',
        path: '/assets/themes/congratulation/FCMB_Shout-Out_Congrats_1.jpg',
      },
      {
        label: 'Theme 2',
        path: '/assets/themes/congratulation/FCMB_Shout-Out_Congrats_2 copy.jpg',
      },
    ],
  },
  {
    categoryName: 'ANNIVERSARY',
    images: [
      {
        label: 'Theme 1',
        path: '/assets/themes/anniversary/FCMB_Shout-Out_Anniversary_1 copy.jpg',
      },
      {
        label: 'Theme 2',
        path: '/assets/themes/anniversary/FCMB_Shout-Out_Anniversary_1.jpg',
      },
    ],
  },
  {
    categoryName: 'APOLOGY',
    images: [
      {
        label: 'Theme 1',
        path: '/assets/themes/apology/apology copy.jpg',
      },
      {
        label: 'Theme 2',
        path: '/assets/themes/apology/i am sorry copy.jpg',
      },
    ],
  },
  {
    categoryName: 'LOVE',
    images: [
      {
        label: 'Theme 1',
        path: '/assets/themes/love/FCMB_Shout-Out_Love_1.jpg',
      },
      {
        label: 'Theme 2',
        path: '/assets/themes/love/FCMB_Shout-Out_Love_2.jpg',
      },
    ],
  },
  {
    categoryName: 'LOSS',
    images: [
      {
        label: 'Theme 1',
        path: '/assets/themes/loss/condolences copy.jpg',
      },
      {
        label: 'Theme 2',
        path: '/assets/themes/loss/sorry for your loss copy.jpg',
      },
    ],
  },
  {
    categoryName: 'MISSINGYOU',
    images: [
      {
        label: 'Theme 1',
        path: '/assets/themes/missingYou/i miss you.jpg',
      },
      {
        label: 'Theme 2',
        path: '/assets/themes/missingYou/thinking of you.jpg',
      },
    ],
  },
  {
    categoryName: 'QUICKRECOVERY',
    images: [
      {
        label: 'Theme 1',
        path: '/assets/themes/quickRecovery/get well soon.jpg',
      },
      {
        label: 'Theme 2',
        path: '/assets/themes/quickRecovery/speedy recovery.jpg',
      },
    ],
  },
];
