import React from "react";
import { Link } from "react-router-dom";


function ShoutOutGrid() {
    return (
        <div id="shoutOutGrid" style={{ backgroundImage: "url(/ima/shout-grid-bg.SVG)", backgroundRepeat: "no-repeat" }}>
            {/* First row */}
            <div className="container">
                <div className="row" >
                    <h3>Categories</h3>
                </div>
            </div>

            <div className="row justify-content-center">

                <div className="col-3">

                    <div className="card">
                        <img src="/ima/love.SVG" className="card-img-top" alt="love" />
                        <div className="card-body">
                            <h5 className="card-title">LOVE</h5>
                            <p className="card-text">Love is when another person’s happiness is more important to you than yours. Tell your loved ones how you really feel about them.</p>
                            <Link to="/love/#category_ellipse" className="card-link">Select<img src="/ima/shoutout-select-vector.SVG" className="card-img-top" alt="love" /></Link>
                        </div>
                    </div>

                </div>
                <div className="col-3">

                    <div className="card">
                        <img src="/ima/anniversary.SVG" className="card-img-top" alt="anniversary" />
                        <div className="card-body">
                            <h5 className="card-title">ANNIVERSARY</h5>
                            <p className="card-text">
                                It's time to celebrate all over again, make this anniversary special by sending a shout out to your Partner/Family and Friends. </p>
                            <Link to="/anniversary" className="card-link">Select<img src="/ima/shoutout-select-vector.SVG" className="card-img-top" alt="love" /></Link>
                        </div>
                    </div>

                </div>

                <div className="col-3">

                    <div className="card">
                        <img src="/ima/congratulations.SVG" className="card-img-top" alt="congratulations" />
                        <div className="card-body">
                            <h5 className="card-title">CONGRATULATIONS</h5>
                            <p className="card-text">Best-wishes to a loved one. /This calls for celebrating, Congratulations! Send a Shout out to your Family and Friends. </p>
                            <Link to="/congratulations" className="card-link">Select<img src="/ima/shoutout-select-vector.SVG" className="card-img-top" alt="love" /></Link>
                        </div>
                    </div>

                </div>
            </div>

            {/* Second row */}
            <div className="row justify-content-center">


                <div className="col-3">

                    <div className="card">
                        <img src="/ima/birthday.SVG" className="card-img-top" alt="birthday" />
                        <div className="card-body">
                            <h5 className="card-title">BIRTHDAY</h5>
                            <p className="card-text">Another year older, another reason to tell your loved ones how special they are/Another reason to send a birthday shout out to your loved one.</p>
                            <Link to="/birthday" className="card-link">Select<img src="/ima/shoutout-select-vector.SVG" className="card-img-top" alt="love" /></Link>

                        </div>
                    </div>

                </div>

                <div className="col-3">

                    <div className="card">
                        <img src="/ima/missing_you.SVG" className="card-img-top" alt="missing_you" />
                        <div className="card-body">
                            <h5 className="card-title">MISSING YOU</h5>
                            <p className="card-text">Missing someone is your heart's way of reminding you that you love them, Express how you feel to your Partner, friends and family.</p>
                            <Link to="/missing-you" className="card-link">Select<img src="/ima/shoutout-select-vector.SVG" className="card-img-top" alt="love" /></Link>

                        </div>
                    </div>

                </div>
                <div className="col-3">

                    <div className="card">
                        <img src="/ima/quick_recovery.SVG" className="card-img-top" alt="quick_recovery" />
                        <div className="card-body">
                            <h5 className="card-title">QUICK RECOVERY</h5>
                            <p className="card-text">Need to tell that special someone how much you would like to see them back on their feet? Send them a ‘get well’ message</p>
                            <Link to="/quick-recovery" className="card-link">Select<img src="/ima/shoutout-select-vector.SVG" className="card-img-top" alt="love" /></Link>

                        </div>
                    </div>

                </div>
            </div>

            {/* Third row */}
            <div className="row justify-content-center">
                <div className="col-3">
                    <div className="card">
                        <img src="/ima/apology1.PNG" className="card-img-top" alt="apology" />
                        <div className="card-body">
                            <h5 className="card-title">APOLOGY</h5>
                            <p className="card-text">Saying ‘I'm sorry’ is actually a show of strength, not weakness. Take the first step and show how strong you are. </p>
                            <Link to="apology" className="card-link">Select<img src="/ima/shoutout-select-vector.SVG" className="card-img-top" alt="love" /></Link>
                        </div>
                    </div>

                </div>
                <div className="col-3">

                    <div className="card">
                        <img src="/ima/loss.SVG" className="card-img-top" alt="loss" />
                        <div className="card-body">
                            <h5 className="card-title">LOSS</h5>
                            <p className="card-text">It is empathetic to show support and succour to your loved ones over a loss. Express your sympathy to your family and friends. </p>
                            <Link to="/loss" className="card-link">Select<img src="/ima/shoutout-select-vector.SVG" className="card-img-top" alt="love" /></Link>

                        </div>
                    </div>

                </div>

                <div className="col-3">

                    <div className="card" style={{ backgroundColor: "#fff", height: "342px" }}>
                        <div className="card-body" style={{ margin: "0 auto", color: "white", height: "25px", background: "rgba(255, 255, 255, 0)", fontFamily: "Brandon Grotesque" }}>
                            <h5 className="card-title btn btn-link" id="comingSoon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon" style={{ marginTop: "120px" }}></h5>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default ShoutOutGrid;