import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltRight,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import MessageService from '../../Services/message.services';
import Header from '../CategoriesHeader';
import Footer from '../footer';
import ReactPaginate from 'react-paginate';
import { GlobalContext } from '../../Context/GlobalProvider';
import OutputSteps from '../Modals/OutputSteps';

function Congratulations() {
  const state = useContext(GlobalContext);
  // console.log("state---", state);
  const [messages, fetchMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageId, setMessageId] = useState(state.messageId);
  const [messageHistoryId, setMessageHistoryId] = useState(
    state.messageHistoryId,
  );
  const [categoryName, setCategoryName] = useState(state.categoryName);

  const [postPerPage] = useState(5);
  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const youGetTheMessage = () => {
      setLoading(true);
      MessageService.getCongratulationMessages()
        .then(
          response => {
            //console.log("getLresponse.dataoveMessages", response.data)
            const slice = response.data.slice(
              offset - 1,
              offset - 1 + postPerPage,
            );
            fetchMessages(slice);
            setPageCount(Math.ceil(response.data.length / postPerPage));
            setLoading(false);
          },
          error => {
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            fetchMessages(_content);
          },
        )
        .catch(e => {
          //console.log(e);
        });
    };
    youGetTheMessage();
  }, [offset, postPerPage]);

  const selectMessage = (id, message) => {
    state.modalState(true);
    state.modalStep(1);
    state.messageIDAction(id);
    state.categoryNameAction('CONGRATULATIONS');
    state.categoryMessageAction(message);
    // state.addCategoryMessageAction("CONGRATULATIONS", messages)

    //setMessageId(id)
    return (
      <OutputSteps
        show={state.showModal}
        messageId={messageId}
        messageHistoryId={messageHistoryId}
      />
    );
  };

  const handlePageClick = event => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
  };

  return (
    <div>
      <Header />
      <div className="jumbotron categoryHeadJumbo">
        <div className="row">
          <div
            className="col-md-6"
            id="category_ellipse"
            style={{
              backgroundImage: 'url(/ima/categories_ellipse_29.SVG)',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="card cardCategory" style={{ width: '18rem' }}>
              <h5
                className="card-title cardCategoryTitleLong"
                style={{ fontSize: '18rem' }}
              >
                CONGRATULATIONS
              </h5>
              <div className="card-body">
                <p className="card-text">
                  Best-wishes to a loved one. /This calls for celebrating,
                  Congratulations! Send a Shout out to your Family and Friends.
                </p>
                <a href="#messageTable">
                  <img src="/ima/purple-gen-btn.SVG" alt="logo" />
                  <a
                    href="#messageTable"
                    className="card-link"
                    style={{ bottom: '-31px' }}
                  >
                    {' '}
                    VIEW MESSAGES
                  </a>
                </a>
              </div>
            </div>
            <div className="category-col-img">
              <img src="/ima/category-small-ellipse.SVG" alt="logo" />
            </div>

            {/* <img src="/ima/categories_ellipse_29.SVG" id="category_ellipse" alt="categories_ellipse_29" />*/}
          </div>
          <div className="col-md-6" id="jumboCategorySecond">
            <div className="" id="background-pink"></div>
            <div className="">
              <img src="/ima/love-hand.PNG" id="love-hand" alt="logo" />
              <img
                src="/ima/congratulations.SVG"
                id="fcmb-two-love-birds"
                alt="logo"
              />
              <img src="/ima/plain_ellipse.SVG" id="plain_ellipse" alt="logo" />
              <img src="/ima/2_love_heart.SVG" id="twoLove_heart" alt="logo" />
              <img src="/ima/love-vector.SVG" id="love-vector" alt="logo" />
              <img src="/ima/love-image.SVG" id="love-image" alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <section id="tableSection">
        <table className="table" id="messageTable">
          <thead className="thead">
            <tr>
              <th scope="col" id="tableCategoryName">
                CONGRATULATIONS SMS
              </th>
              <th scope="col">
                <nav aria-label="Page navigation example">
                  <ReactPaginate
                    previousLabel={
                      <FontAwesomeIcon
                        className="chevronLeftIcon"
                        icon={faChevronLeft}
                      />
                    }
                    nextLabel={
                      <FontAwesomeIcon
                        className="chevronRightIcon"
                        icon={faChevronRight}
                      />
                    }
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                </nav>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className="d-flex justify-content-center align-items-center w-100 h-100">
                Loading...
              </tr>
            ) : (
              messages?.map((messages, index) => (
                <tr key={messages.id}>
                  <td scope="row" id="tableMsgRow">
                    {messages.message}
                  </td>
                  <td id="tableActionRow">
                    <button
                      onClick={() =>
                        selectMessage(messages.id, messages.message)
                      }
                      className="btn btn-link"
                    >
                      View message{' '}
                      <FontAwesomeIcon
                        className="arrowIcon"
                        icon={faLongArrowAltRight}
                      />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {state.showModal ? <OutputSteps show={state.showModal} /> : null}
      </section>
      <Footer />
    </div>
  );
}

export default Congratulations;
