import React, { useState,useContext } from "react";
import ReactDOM from "react-dom";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/esm/Button";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {GlobalContext} from "./../../Context/GlobalProvider"


const IsAccountOpen = (props) => {
  
  // context api
  const state = useContext(GlobalContext)
  const controller =(num)=>{

    if(inputs.accountNumber ===""){
      state.notifyServerGlobal("Fields cannot be empty")
      return
    }

    if(inputs.accountNumber.length <10 || inputs.accountNumber.length > 10 ){
      state.notifyServerGlobal("Account number should be 10 digits")
      return
    }
      state.notifyServerGlobal("");
      state.modalState(true)
      state.modalStep(num)
      state.userInfo({...inputs})
  }
  const closeModal=()=>{
      state.modalState(false)
      state.modalStep(1)
  }
const [inputs,setInputs] = useState({accountNumber:"", remember:false})

const handleChange=(e)=>{
  switch (e.target.type) {
    case "checkbox":
      if(e.target.checked){
        //console.log(e.target.checked)
        setInputs({...inputs,remember:true})
      }else{
        setInputs({...inputs,remember:false})
      }
      break;
    case "text":
      if(Number(e.target.value) && e.target.value!==" "){
        setInputs(({...inputs,accountNumber:e.target.value.trim()}))
      }
      if(e.target.value==="" && Number(e.target.value)===0 ){
        setInputs(({...inputs,accountNumber:e.target.value.trim()}))
      }
     else{
        return null
      }
      break;
    default:
      break;
  }
}

  return ReactDOM.createPortal(
    <div>
      <Modal
        show={state.showModal}
        // onHide={()=>do something}
        backdrop={props.backdrop}
        keyboard={false}
        id="modal-wrapper"
      >
        <div id="gradient-modal-bar"></div>
        <Modal.Header
          className="rounded-0 mt-2"
          closeButton
          onClick={() => closeModal()}
        ></Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="form-fields">
              <h5>Please provide your account number</h5>
              <em className="text-danger">{state.notifyServer}</em>
              <div className="kyc-input-wrapper mb-2">
                <input placeholder="Account Number" value={inputs.accountNumber} onChange={(e)=>handleChange(e)} type="text" maxLength={10} name="accountNumber" />
              </div>
              <div className="kyc-input-wrapper check-wrapper mb-2">
                <input type="checkbox" name="remember" onChange={(e)=>handleChange(e)} checked={inputs.remember} />
                <span className="checkbox-cover">
                  <FontAwesomeIcon size="xs" icon={faCheck} />
                </span>
                <label className="pt-2">Save details for next time</label>
              </div>
            </div>
            <div className="">
              <Button
                variant="secondary"
                id="accSecondaryBtn"
                onClick={() => closeModal()}
              >
                CANCEL
              </Button>
              <Button variant="primary" id="accPrimaryBtn" onClick={() => controller(7)}>
                PROCEED
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>,
    document.getElementById("modal-root")
  );
};

export default IsAccountOpen;
