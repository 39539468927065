import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { GlobalContext } from './../../Context/GlobalProvider';
import MessageService from '../../Services/message.services';
// import DOMPurify from "dompurify";

const NoAccount = props => {
  // context api
  const state = useContext(GlobalContext);

  const [messages, fetchPreviewMessage] = useState([]);
  const [otpSentMessage, fetchOtpSentMessage] = useState('');
  const [Isloading, setLoading] = useState(false);

  const controller = num => {
    MessageService.SendOtpToSender(
      state.messageHistoryId,
      state.user.accountNumber,
    )
      .then(
        response => {
          fetchOtpSentMessage(response.data.description);
          state.notifyServerGlobal(response.data.description);
        },
        error => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          fetchOtpSentMessage(_content);
          state.notifyServerGlobal(_content.data.description);
        },
      )
      .catch(e => {
        console.log(e);
      });

    state.modalState(true);
    state.modalStep(num);
  };

  const closeModal = () => {
    state.modalState(false);
    state.modalStep(1);
  };

  useEffect(() => {
    const youGetTheMessage = () => {
      //console.log("state.step", state);
      //payload for preview message
      var data = [
        {
          messageId: state.messageId,
          senderUserName: state.user.preferredName,
          receiverUsername: state.user.recipientName,
          phoneNumber: state.user.recipientPhone,
          emailAddress: state.user.recipientEmail,
          accNo: state.user.accountNumber,
        },
      ];
      // console.log("data", data);

      const serializedData = JSON.stringify(data);
      // console.log("serializedData", serializedData);

      //  console.log("raw data", data)
      setLoading(true);
      MessageService.PreviewMessages(serializedData)
        .then(
          response => {
            // if(response.data === `This ${state.user.accountNumber} is invalid or Phone number not profiled`){
            //   state.notifyServerGlobal(response.data + ". Try again");
            //   return
            // }

            // if(response.data.previewMessages == null){
            //   // console.log("response.data in Preview message previewMessages",response.serializedData.previewMessages);
            //   // console.log("response.data in response.data.message",response.data.message)
            //   state.notifyServerGlobal("An error has occured "+ response.data.message + ". Try again");
            //   return
            // }

            fetchPreviewMessage(response.data.list);
            state.messageHistoryIdAction(response.data.messageHistoryId);
            setLoading(false);
          },
          error => {
            const _content =
              (error.response && error.response.data.list) ||
              error.message ||
              error.toString();
            fetchPreviewMessage(_content);
          },
        )
        .catch(e => {
          //console.log(e);
        });
    };
    youGetTheMessage();
  }, []);

  return ReactDOM.createPortal(
    <div>
      <Modal
        show={state.showModal}
        // onHide={()=>do something}
        backdrop="static"
        keyboard={false}
        id="isAccountOpenModal"
      >
        <div id="gradient-modal-bar"></div>
        <Modal.Header
          className="rounded-0 mt-2"
          closeButton
          onClick={() => props.handleClose()}
        ></Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4">
              <img
                src="/ima/sending_message_by_sender.SVG"
                id="isAccImg"
                alt="isAccountOpen"
              />
            </div>
            <div className="col-md-8">
              <div className="isAccountOpenHeaderCol8">
                <h6 className="text-danger">{state.notifyServer}</h6>
                <div className="msg-preview">
                  {/* send as an array list to the backend */}
                  {/* 
                  {messages.map((message, index) => {
                    const purifiedMessage = DOMPurify.sanitize(
                      JSON.parse(JSON.stringify(message.previewMessages))
                    )
                    return (
                      <div
                        // contentEditable="true"
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: purifiedMessage,
                        }}
                      />
                    );
                  })} */}

                  {/* <p>To be fixed</p> */}
                  {/* {messages.previewMessages} */}
                </div>
                <div className="preview-disc-wrapper">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <p>
                    You are about to send this FCMB Shout Out message to{' '}
                    <b>{state.user.recipientName}</b> and will be charged N10
                    for the service.
                    <em className="fs-6">
                      Please note, standard SMS cost still applies
                    </em>
                  </p>
                </div>
              </div>
              <div className="isAccountOpenHeaderCol8">
                <Button
                  variant="secondary"
                  id="accSecondaryBtn"
                  onClick={() => closeModal()}
                >
                  CANCEL
                </Button>
                <span>
                  <Button
                    variant="primary"
                    id="accPrimaryBtn"
                    onClick={() => controller(8)}
                    disabled={Isloading}
                  >
                    PROCEED
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>,
    document.getElementById('modal-root'),
  );
};

export default NoAccount;
