import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../Context/GlobalProvider';
import { images } from '../../helper/ImageHelper';
import './EmailMessages.css';
import { Button, Modal } from 'react-bootstrap';

export const EmailMessages = ({ onNext, onBack }) => {
  const state = useContext(GlobalContext);
  const [categoryName, setCategoryName] = useState(state.categoryName);
  const [categoryImages, setCategoryImages] = useState(null);
  const [categoryMessages, setCategoryMessages] = useState('');
  const [selectedTheme, setSelectedTheme] = useState(state?.imageType ?? 0);
  const [showModal, setShowModal] = useState(false);

  const controller = num => {
    // state.modalState(true)
    state.imageTypeAction(num);
  };

  useEffect(() => {
    const imageData = images.find(
      image => image.categoryName === state.categoryName,
    );
    if (imageData) {
      setCategoryImages(imageData);
    }
    const youGetTheMessage = () => {
      setCategoryMessages(state.selectedMesage);
    };
    youGetTheMessage();
  }, []);

  const handleSubmit = () => {
    state.imageTypeAction(Number(selectedTheme));
    onNext();
  };

  return (
    <div className="email_details">
      {/* create a form with the user details */}
      <p className="details_p">Preferred theme and message</p>

      <div className="dotted_messages_box">
        <div>
          <div className="dotted_message_item"></div>
        </div>

        <div>
          {categoryImages && (
            <div style={{ marginTop: '15px' }} className="dotted_message_item">
              <img
                style={{
                  width: '9.5625rem',
                  height: '6.6875rem',
                  borderRadius: '0.375rem',
                }}
                src={
                  categoryImages.images[selectedTheme ? selectedTheme : 0].path
                }
                alt="categoryImg"
                onClick={() => {
                  // setSelectedImage(index);
                  // setShowModal(true);
                }}
              />
            </div>
          )}
        </div>

        {/* {showModal && (
          <div className="modal">
            <div className="modal-content">
              <div>
                {categoryImages &&
                categoryImages.images.map((image, index) => (
                  <label key={index}>
                    <input
                      type="radio"
                      checked={selectedImage === index}
                      onChange={() => setSelectedImage(index)}
                    />
                    <img className="modal-image-item" src={image} alt={"categoryImage"} />
                  </label>
                ))}
              </div>
              <button
                className="modal-close"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        )} */}
        <Modal
          show={showModal}
          // onHide={()=>do something}
          keyboard={false}
          id="isAccountOpenModal"
        >
          <div></div>
          <Modal.Header
            className="rounded-0 mt-2"
            closeButton
            onClick={() => setShowModal(false)}
          ></Modal.Header>

          <Modal.Body>
            <div>
              <div
                className="col-md-8"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  {categoryImages &&
                    categoryImages.images.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '1rem',
                          border: '1px solid #E5E5E5',
                        }}
                      >
                        <label key={index}>
                          <input
                            type="radio"
                            checked={state.imageType === index}
                            onChange={() => controller(index)}
                          />
                          <img
                            className="modal-image-item"
                            src={image.path}
                            alt={'categoryImage'}
                          />
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div>
          {/* category name */}
          <p
            style={{
              color: '#5C068C',
              paddingTop: '1rem',
              paddingLeft: '1rem',
              fontFamily: 'segoe UI',
              fontSize: '1rem',
              fontWeight: '700',
            }}
          >
            {categoryName}
          </p>
          {/* category description */}
          <p
            style={{
              color: '#575756',
              paddingLeft: '1rem',
              fontFamily: 'segoe UI',
              fontStyle: 'normal',
              fontSize: '0.875rem',
              fontWeight: '400',
            }}
          >
            {categoryMessages}
          </p>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2rem',
          width: '100%',
          maxWidth: '698px',
          paddingLeft: '1rem',
        }}
      >
        <div>
          <label style={{ fontWeight: '500' }}>Change Theme</label>
          <select
            onChange={e => setSelectedTheme(e.target.value)}
            className="details_input"
            value={selectedTheme}
          >
            <option disabled>Select Theme</option>
            <option value="0">Theme 1</option>
            <option value="1">Theme 2</option>
          </select>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
          }}
        >
          <p className="back_text" onClick={onBack}>
            BACK
          </p>
          <button className="details_button" onClick={handleSubmit}>
            NEXT
          </button>
        </div>
      </div>
    </div>
  );
};
