import axios from 'axios';

const API_URL = process.env.REACT_APP_ApiBaseUrl;
const Token = process.env.REACT_APP_Auth_Token;

//const API_URL = "http://172.27.9.82:8090/api/";
//const Token = "RmNtYlNob3V0T3V0OndlbGNvbWVAMTIz";

//console.log("Token-menu-service", Token);
//console.log("API_URL-menu-service", API_URL)

const getMenuCategories = () => {
  //const response = axios.get(API_URL + "GetAllCategories", { headers: Token });
  return axios.get(API_URL + 'FCMBShoutOut', {
    headers: {
      ApiKey: `${Token}`,
      'Cross-Origin-Opener-Policy': 'same-origin',
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
    },
  });
};

export default {
  getMenuCategories,
};
