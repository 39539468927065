import { useContext, useState } from 'react';
import { UserDetails } from '../../pages/userDetails/UserDetails';
import { Messages } from '../../pages/messages/Messages';
import { Recipients } from '../../pages/recipients/Recipients';
import CategoriesHeader from '../CategoriesHeader';
import { Preview } from '../../pages/preview/Preview';
// import { Confirmation } from "../../pages/confirmation/Confirmation";
import { MessagesSent } from '../../pages/messagesSent/MessagesSent';
import { EmailMessages } from '../../pages/messages/EmailMessages';
import { RecipientEmail } from '../../pages/recipients/RecipientEmail';
import { GlobalContext } from '../../Context/GlobalProvider';
import OutputSteps from '../Modals/OutputSteps';
import Connector from '../../helper/svg/Connector';
import { useEffect } from 'react';

//Todo: create an enum for the message medium
const MessageMediumEnum = {
  sms: 1,
  email: 2,
};

export const initialRecipientData = {
  receiverUsername: '',
};

export const DynamicRoute = () => {
  // declare the active state variable for the progress stepper
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState(MessageMediumEnum.sms);
  const [recipientData, setRecipientData] = useState([initialRecipientData]);

  const state = useContext(GlobalContext);

  // declare the steps array
  const steps = [
    'Your details',
    'Messages',
    'Recipients',
    'Preview',
    'Confirmation',
  ];

  // declare the function to handle the next button
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  // declare the function to handle the back button
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  //create a handleChanege function to handle the change in the message medium
  const handleMessageMediumOptionChange = e => {
    //create a select value to hold the selected value
    const selectedValue = +e.target.value;
    console.log('selectedValue', selectedValue);
    setSelectedOption(selectedValue);
    state.messageTypeAction(selectedValue);

    //perform a check to see if the selected value is sms
    // onChange(selectedValue);
  };

  const controller = () => {
    state.modalState(true);
    state.modalStep(6);

    return <OutputSteps show={state.showModal} />;
  };

  // declare the function to handle the stepper
  // const handleStepper = () => {
  //     return (
  //         <div className="progress_step_indicator_body">
  //             {steps.map((step, index) => (
  //                 <div
  //                     className={`progress_step_item ${
  //                         index === activeStep ? "active" : ""
  //                     }`}
  //                 >
  //                     <div className="circle"></div>
  //                     <h5 className="text">{step}</h5>
  //                 </div>
  //             ))}
  //         </div>
  //     );
  // }

  // declare function to render compinent based on the active step
  const renderComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <UserDetails
            onNext={handleNext}
            onBack={handleBack}
            onChange={e => handleMessageMediumOptionChange(e)}
            value={selectedOption}
          />
        );
      case 1:
        return selectedOption === MessageMediumEnum.sms ? (
          <Messages onNext={handleNext} onBack={handleBack} />
        ) : (
          <EmailMessages onNext={handleNext} onBack={handleBack} />
        );

      case 2:
        return selectedOption === MessageMediumEnum.sms ? (
          <Recipients
            setRecipientData={setRecipientData}
            recipientData={recipientData}
            onNext={handleNext}
            onBack={handleBack}
          />
        ) : (
          <RecipientEmail
            setRecipientData={setRecipientData}
            recipientData={recipientData}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 3:
        return (
          <Preview
            onNext={handleNext}
            onBack={handleBack}
            recipientData={recipientData}
            controller={controller}
          />
        );
      case 4:
        return <MessagesSent />;
      default:
        return <UserDetails />;
    }
  };

  return (
    <>
      <CategoriesHeader />
      <div className="flex_body">
        <div className="progress_step_indicator_body">
          {steps.map((step, index) => (
            <div key={index}>
              <div
                className={`progress_step_item ${
                  index === activeStep ? 'active' : ''
                }
            ${index < activeStep ? 'completed' : ''}`}
              >
                <div className="circle"></div>
                <h5 className="text">{step}</h5>
              </div>
              {steps.length - 1 !== index && (
                <div style={{ paddingLeft: '14px' }}>
                  <Connector />
                </div>
              )}
            </div>
          ))}
        </div>

        <div>
          {/* dynamically render the component based on the active step */}
          {renderComponent()}
        </div>
      </div>
    </>
  );
};
