import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import Footer from './footer';
import HeroSlider from './HeroSlider';
import ShoutOutGrid from './ShoutOutGrid';
import HowItWorks from './HowItWorks';

function Home() {

    const [showState, setShowState] = useState(false)

    const buttonClickedHandler = () => {
        setShowState((showState) => showState = !showState)
    }
    return (
        <div className="homeBg">
            <HeroSlider buttonClicked={buttonClickedHandler} />
            <HowItWorks />
            <ShoutOutGrid />
            <Footer />
        </div>
    );
}

export default Home;