//First step
//actions
export const logOut = dispatch => {
  dispatch({
    type: 'LOGOUT',
  });
};

export const userInfo = (data, dispatch) => {
  // console.log(data);
  dispatch({
    type: 'USER-INFO',
    payload: data,
  });
};

export const userInfoEditHandler = (data, dispatch) => {
  if (Object.keys(data)[0] === 'phone' && isNaN(data.phoneNumber)) return null;
  dispatch({
    type: 'USER-INFO-EDITOR',
    payload: data,
  });
};

export const notifyServer = (data, dispatch) => {
  dispatch({
    type: 'NOTIFICATION-SERVER',
    payload: data,
  });
};

export const messageId = (data, dispatch) => {
  dispatch({
    type: 'MESSAGE-ID',
    payload: data,
  });
};

export const messageHistoryId = (data, dispatch) => {
  dispatch({
    type: 'MESSAGEHISTORY-ID',
    payload: data,
  });
};

export const categoryName = (data, dispatch) => {
  dispatch({
    type: 'CATEGORY-NAME',
    payload: data,
  });
};

export const addCategoryMessage = (message, dispatch) => {
  dispatch({
    type: 'ADD-CATEGORY-MESSAGE',
    payload: message,
  });
};

export const imageType = (data, dispatch) => {
  dispatch({
    type: 'IMAGE-TYPE',
    payload: data,
  });
};

export const modalState = (data, dispatch) => {
  dispatch({
    type: 'MODAL-STATE',
    payload: data,
  });
};
export const modalStep = (data, dispatch) => {
  dispatch({
    type: 'MODAL-STEP',
    payload: data,
  });
};

export const messageType = (data, dispatch) => {
  dispatch({
    type: 'MESSAGE-TYPE',
    payload: data,
  });
};
