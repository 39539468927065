import React, { useState,useContext } from "react";
import ReactDOM from "react-dom";
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import {GlobalContext} from "./../../Context/GlobalProvider"

const SendRecipientDetails = (props) => {
      // context api
  const state = useContext(GlobalContext)
  const controller =(num)=>{
      state.modalState(true)
      state.modalStep(num)
  }
  const closeModal=()=>{
      state.modalState(false)
      state.modalStep(1)

  }
    return ReactDOM.createPortal(
        <div >
            <Modal show={state.showModal} onHide={props.buttonClicked} backdrop="static" keyboard={false} id="isAccountOpenModal">
        <div id="gradient-modal-bar"></div>
                <Modal.Header className="rounded-0 mt-2" closeButton
                    onClick={() => closeModal()}
                >
                </Modal.Header>
                <Modal.Body>
                    <div class="row">
                        <div class="col-md-4">
                            <img src="/ima/message_sent_high_five.SVG" id="isAccImg" alt="isAccountOpen" />
                        </div>
                        <div class="col-md-8" >
                            <div className="isAccountOpenHeaderCol8">
                                <div className='mt-4 mb-3'>FCMB Shout Out message to <b>{state.user.recipientName} is sending...</b>{state.notifyServer}</div>
                            </div>
                            <div className="isAccountOpenHeaderCol8">
                                <span>
                                    <Button variant="primary" id="accPrimaryBtn" 
                                    onClick={()=>closeModal()}
                                    >RETURN HOME</Button>
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>, document.getElementById("modal-root")
    )

};

export default SendRecipientDetails