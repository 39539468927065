import { useContext, useEffect, useState } from 'react';
import './Messages.css';
import { GlobalContext } from '../../Context/GlobalProvider';

export const Messages = ({ onNext, onBack }) => {
  const state = useContext(GlobalContext);
  const [categoryName, setCategoryName] = useState(state.categoryName);
  const [categoryMessages, setCategoryMessages] = useState('');

  useEffect(() => {
    const categoryName = state.categoryName;
    setCategoryName(categoryName);

    const youGetTheMessage = () => {
      setCategoryMessages(state.selectedMesage);
    };
    youGetTheMessage();
  }, []);

  return (
    <div className="messages_details">
      {/* create a form with the user details */}
      <p className="details_p">Preferred message</p>

      <div className="dotted_messages_box">
        <div>
          <p
            style={{
              color: '#5C068C',
              paddingTop: '1rem',
              paddingLeft: '1rem',
              fontFamily: 'segoe UI',
              fontSize: '1rem',
              fontWeight: '700',
            }}
          >
            {categoryName}
          </p>

          <p
            style={{
              color: '#575756',
              paddingLeft: '1rem',
              fontFamily: 'segoe UI',
              fontStyle: 'normal',
              fontSize: '0.875rem',
              fontWeight: '400',
            }}
          >
            {categoryMessages}
          </p>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2rem',
          width: '100%',
          maxWidth: '698px',
          paddingLeft: '1rem',
        }}
      >
        {/* <button className="add_more_button">ADD MORE</button> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            float: 'end',
            flexGrow: 1,
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <p className="back_text" onClick={onBack}>
              BACK
            </p>
            <button className="details_button" onClick={onNext}>
              NEXT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
