import React, { createContext, useState, useReducer, useEffect } from 'react';
import AppReducer from './Reducer';
import {
  logOut,
  userInfo,
  addCategoryMessage,
  userInfoEditHandler,
  modalState,
  modalStep,
  messageId,
  messageHistoryId,
  categoryName,
  notifyServer,
  imageType,
  messageType,
} from './Actions';

//Third Step
// console.log(logState);
//initial state
const initialState = JSON.parse(localStorage.getItem('appState')) || {
  islogged: false,
  user: {},
  showModal: false,
  step: 1,
  messageId: 0,
  categoryName: '',
  selectedMessage: '',
  addCategoryMessage: [],
  imageType: 0,
  messageHistoryId: 0,
  notifyServer: '',
  messageType: 1,
};

//globalContext
export const GlobalContext = createContext(initialState);

//global provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const [selectedImage, setSelectedImage] = useState(state.imageType);

  const handleMessageClick = message => {
    dispatch(addCategoryMessage(message));
  };

  useEffect(() => {
    localStorage.setItem('appState', JSON.stringify(state));
  }, [state]);

  return (
    <GlobalContext.Provider
      value={{
        messageId: state.messageId,
        categoryName: state.categoryName,
        selectedMesage: state.selectedMesage,
        handleMessageClick,
        addCategoryMessage: state.addCategoryMessage,
        imageType: state.imageType,
        selectedImage,
        notifyServer: state.notifyServer,
        messageHistoryId: state.messageHistoryId,
        islogged: state.islogged,
        showModal: state.showModal,
        step: state.step,
        user: state.user,
        messageType: state.messageType,
        messageTypeAction: data => messageType(data, dispatch),
        modalState: data => modalState(data, dispatch),
        modalStep: data => modalStep(data, dispatch),
        logOut: () => logOut(dispatch),
        userInfo: data => userInfo(data, dispatch),
        notifyServerGlobal: data => notifyServer(data, dispatch),
        messageIDAction: data => messageId(data, dispatch),
        categoryNameAction: data => categoryName(data, dispatch),
        categoryMessageAction: data => addCategoryMessage(data, dispatch),
        imageTypeAction: data => {
          imageType(data, dispatch);
          setSelectedImage(data);
        },
        messageHistoryIdAction: data => messageHistoryId(data, dispatch),
        userInfoEditHandler: data => userInfoEditHandler(data, dispatch),
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
