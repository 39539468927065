//Second Step

const AppReducer = (state, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return {
        ...state,
        islogged: false,
      };
    case 'USER-INFO':
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    case 'MESSAGE-ID':
      return {
        ...state,
        messageId: action.payload,
      };

    case 'MESSAGEHISTORY-ID':
      return {
        ...state,
        messageHistoryId: action.payload,
      };

    case 'CATEGORY-NAME':
      return {
        ...state,
        categoryName: action.payload,
      };

    case 'ADD-CATEGORY-MESSAGE':
      return {
        ...state,
        selectedMesage: action.payload,
      };

    case 'IMAGE-TYPE':
      return {
        ...state,
        imageType: action.payload,
      };

    case 'NOTIFICATION-SERVER':
      return {
        ...state,
        notifyServer: action.payload,
      };

    case 'USER-INFO-EDITOR':
      let user = state.user;
      return {
        ...state,
        user: { ...user, ...action.payload },
      };
    case 'MODAL-STATE':
      return {
        ...state,
        showModal: action.payload,
      };
    case 'MODAL-STEP':
      return {
        ...state,
        step: action.payload,
      };
    case 'MESSAGE-TYPE':
      return {
        ...state,
        messageType: action.payload,
      };
    default:
      return state;
  }
};
export default AppReducer;
