import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import { GlobalContext } from './../../Context/GlobalProvider';
import { useHistory } from 'react-router-dom';

const IsAccountOpen = props => {
  //navigate isAccountOpen modal to dynamic routing page
  let history = useHistory();

  // context api
  const state = useContext(GlobalContext);
  const controller = num => {
    state.modalState(true);
    state.modalStep(num);
  };
  const closeModal = () => {
    state.modalState(false);
    state.modalStep(1);
  };
  return ReactDOM.createPortal(
    <div>
      <Modal
        show={state.showModal}
        // onHide={()=>do something}
        backdrop={props.backdrop}
        keyboard={false}
        id="isAccountOpenModal"
      >
        <div id="isAccountOpenModal"></div>
        <Modal.Header
          className="rounded-0 mt-2"
          closeButton
          onClick={() => closeModal()}
        ></Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4">
              <img
                src="/ima/isAccountOpen.SVG"
                id="isAccImg"
                alt="isAccountOpen"
              />
            </div>
            <div
              className="col-md-8"
              style={{ padding: '45px 25px 25px 25px' }}
            >
              <div className="isAccountOpenHeaderCol8">
                <p>Do you have an FCMB account?</p>
              </div>
              <div className="isAccountOpenHeaderCol8">
                <Button
                  variant="secondary"
                  id="accSecondaryBtn"
                  onClick={() => controller(2)}
                >
                  NO
                </Button>
                <span>
                  {/* <Button variant="primary" id="accPrimaryBtn" onClick={()=>controller(5)}>YES</Button> */}
                  <Button
                    variant="primary"
                    id="accPrimaryBtn"
                    onClick={() => {
                      history.push('/send-shoutout');
                      state.modalState(false);
                    }}
                  >
                    YES
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* 
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.buttonClicked}>
                        Close
          </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer>
*/}
      </Modal>
    </div>,
    document.getElementById('modal-root'),
  );
};

export default IsAccountOpen;
