import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/esm/Button";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalContext } from "./../../Context/GlobalProvider"
import MessageService from '../../Services/message.services';


const IsAccountOpen = (props) => {
  
  // context api
  const state = useContext(GlobalContext);
  const [inputs, setInputs] = useState("");
  const [confirmOtp, setConfirmOtp] = useState("");
  const [otpSentMessage, fetchOtpSentMessage] = useState("")
  //console.log("sendOtp-module", state)
 
  const controller = (num) => {

    if(inputs === "" || inputs === undefined){
      state.notifyServerGlobal("Otp Field Cannot be empty");
      return
    }

    if(inputs.length > 4 || inputs.length < 4){
      state.notifyServerGlobal("Otp should be 4 digits");
      return
    }
    state.notifyServerGlobal("");
    MessageService.ValidateOtpAndSendMessage(inputs)
    .then(response => {
      setConfirmOtp(response.data.description)
      state.notifyServerGlobal(response.data);
      //console.log("confirmOtp",response.data.description)
    });
      state.notifyServerGlobal("");

    state.modalState(true)
    state.modalStep(num)
  }
  const closeModal = () => {
    state.modalState(false)
    state.modalStep(1)

  }
 
  //console.log("Otpinputs",inputs)
  const handleChange = (e) => {
    if (Number(e.target.value) && e.target.value !== " ") {
      setInputs(e.target.value.trim())
    }
    if (e.target.value === "" && Number(e.target.value) === 0) {
      setInputs(e.target.value.trim())
    }
  }

  const resendOpt = () => {
    state.notifyServerGlobal("");
    MessageService.SendOtpToSender(state.messageHistoryId, state.user.accountNumber)
    .then(response => {
      fetchOtpSentMessage(response.data.description);
      //console.log("state.messageId@preview",response.data.description);
      //console.log("Otp response",otpSentMessage.message)
      state.notifyServerGlobal(response.data.description + " again");
      //console.log("state.messpreviw-modeld",state)
      //console.log("otpSentMessage .msgpreview-modeld",otpSentMessage)
  
    },(error) => {
      const _content =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
        fetchOtpSentMessage(_content);
        state.notifyServerGlobal(_content.data.description);
    }).catch(e => {
      //console.log(e);
    });
  };

  return ReactDOM.createPortal(
    <div>
      <Modal
        show={state.showModal}
        // onHide={do something}
        backdrop={props.backdrop}
        keyboard={false}
        id="modal-wrapper"
      >
        <div id="gradient-modal-bar"></div>
        <Modal.Header
          className="rounded-0 mt-2"
          closeButton
          onClick={() => closeModal()}
        ></Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="form-fields">
              <h5>Please enter OTP sent to your phone number</h5>
              <h6 className="text-danger">{state.notifyServer}</h6>
              <div className="kyc-input-wrapper mb-2">
                <input placeholder="Input OTP here" value={inputs} onChange={(e) => handleChange(e)} type="text" maxLength={10} name="otp" />
              </div>
              <div className="kyc-input-wrapper check-wrapper mb-2">
             
                <label className="pt-2">Didn't get OTP ?  <Button style={{marginBottom: "4px"}} variant="link" onClick={() => resendOpt()}>Resend</Button></label>
              </div>
            </div>
            <div className="">
              <Button
                variant="secondary"
                id="accSecondaryBtn"
                onClick={() => closeModal()}
              >
                CANCEL
              </Button>
              <Button variant="primary" id="accPrimaryBtn" onClick={() => controller(9)}>
                PROCEED
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>,
    document.getElementById("modal-root")
  );
};

export default IsAccountOpen
