import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../Context/GlobalProvider';
import MessageService from '../../Services/message.services';
import Caret from '../../helper/svg/caret.svg';
import './Preview.css';
import { Button, Modal } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { images } from '../../helper/ImageHelper';
import { useSendOtp } from '../../Services/useSendOtp';
import { useValidateOTPAndSendSms } from '../../Services/useValidateOTPAndSendSms';
import { NumericFormat } from 'react-number-format';
import VerifyOtp from './VerifyOtp';

export const Preview = ({ onBack, onNext, recipientData }) => {
  const state = useContext(GlobalContext);
  const [showFields, setShowFields] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [previewMessages, setPreviewMessages] = useState('');
  const [previewImg, setPreviewImg] = useState('');
  const [Isloading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOtpOpen, setIsOtpOpen] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [otpInput, setOtpInput] = useState('');
  const [inputs, setInputs] = useState({
    accountNumber: '',
    remember: false,
  });

  const {
    error: sendOtpError,
    loading: sendOtpIsLoading,
    data: sendOtpData,
    triggerSendOtp,
  } = useSendOtp();

  const {
    error: sendMessageError,
    loading: sendMessageIsLoading,
    data: sendMessageData,
    triggerSendMessage,
  } = useValidateOTPAndSendSms();

  const [messageHistoryId, setMessageHistoryId] = useState([]);
  const imageType = state.imageType;
  const category = state.categoryName;

  const getPreviewImage = () => {
    const filtered = images.filter(obj => {
      return obj.categoryName === category;
    });
    setPreviewImg(filtered[0]?.images[imageType]?.path);
  };

  const toggleFields = () => {
    setShowFields(!showFields);
  };

  // setIsModalOpen(!isModalOpen);
  const handleController = () => {
    if (inputs.accountNumber === '') {
      state.notifyServerGlobal('Fields cannot be empty');
      return;
    }

    if (inputs.accountNumber.length < 10 || inputs.accountNumber.length > 10) {
      state.notifyServerGlobal('Account number should be 10 digits');
      return;
    }
    state.notifyServerGlobal('');
    // state.modalState(true)
    // state.modalStep(num)
    state.userInfo({ ...inputs });
    setIsOtpOpen(true);
  };

  // const modalClose = () => {
  //   setIsModalOpen(false);
  //   setIsOtpOpen(false);
  //   setIsVerified(false);
  // };

  const handleChange = e => {
    switch (e.target.type) {
      case 'checkbox':
        if (e.target.checked) {
          //console.log(e.target.checked)
          setInputs({ ...inputs, remember: true });
        } else {
          setInputs({ ...inputs, remember: false });
        }
        break;
      case 'text':
        if (Number(e.target.value) && e.target.value !== ' ') {
          setInputs({ ...inputs, accountNumber: e.target.value.trim() });
        }
        if (e.target.value === '' && Number(e.target.value) === 0) {
          setInputs({ ...inputs, accountNumber: e.target.value.trim() });
        } else {
          return null;
        }
        break;
      default:
        break;
    }
  };

  const handleOtpChange = e => {
    if (Number(e.target.value) && e.target.value !== ' ') {
      setOtpInput(e.target.value.trim());
    }
    if (e.target.value === '' && Number(e.target.value) === 0) {
      setOtpInput(e.target.value.trim());
    }
  };

  const sendOtp = () => {
    triggerSendOtp(messageHistoryId, state.user.accountNumber);
  };

  useEffect(() => {
    const youGetTheMessage = () => {
      setCategoryName(state.categoryName);

      // setPreviewImg(state.imageType);
    };
    youGetTheMessage();
    getPreviewImage();
  }, [state.imageType, state.categoryName]);

  useEffect(() => {
    const youGetTheMessage = () => {
      const newRecipientData =
        recipientData[0]?.receiverUsername &&
        recipientData?.map(recipient => ({
          ...recipient,
          accNo: state.user.accountNumber,
          imageType: state.imageType,
          senderUserName: state.user.preferredName,
        }));

      if (newRecipientData[0]?.receiverUsername) {
        var data = {
          messageId: state.messageId,
          messageType: state.messageType,
          imageType: state.imageType,
          receipients: newRecipientData,
        };

        const serializedData = JSON.stringify(data);
        setLoading(true);
        MessageService.PreviewMessages(serializedData)
          .then(
            response => {
              setLoading(false);
              const messageHistoryIdArray = [];
              setPreviewMessages(response.data[0].previewMessages);
              response?.data?.map(data =>
                messageHistoryIdArray.push(data?.messageHistoryId),
              );

              setMessageHistoryId(messageHistoryIdArray);
            },
            error => {
              const _content =
                (error.response && error.response.data.receipients) ||
                error.message ||
                error.toString();
              setLoading(false);
              setPreviewMessages(_content);
            },
          )
          .catch(error => {
            console.error();
          });
      } else {
        var data = {
          messageId: state.messageId,
          messageType: state.messageType,
          imageType: state.imageType,
          receipients: [
            {
              senderUserName: state.user.preferredName,
              receiverUsername: state.user.recipientName,
              accNo: state.user.accountNumber,
              imageType: state.imageType,
            },
          ],
        };

        if (state.messageType === 1) {
          data.receipients[0].phoneNumber = state?.user?.recipientPhoneNumber;
        } else if (state.messageType === 2) {
          data.receipients[0].emailAddress = state?.user?.recipientEmail;
        }

        const serializedData = JSON.stringify(data);
        setLoading(true);
        MessageService.PreviewMessages(serializedData)
          .then(
            response => {
              const messageHistoryIdArray = [];
              setLoading(false);
              setPreviewMessages(response.data[0].previewMessages);
              response?.data?.map(data =>
                messageHistoryIdArray.push(data?.messageHistoryId),
              );
              setMessageHistoryId(messageHistoryIdArray);
            },
            error => {
              const _content =
                (error.response && error.response.data.receipients) ||
                error.message ||
                error.toString();
              setLoading(false);
              setPreviewMessages(_content);
            },
          )
          .catch(error => {
            console.error();
          });
      }
    };
    youGetTheMessage();
  }, []);

  const handleSendOtp = () => {
    if (sendOtpData) {
      setIsOtpOpen(prev => !prev);
      return;
    }
    sendOtp();
  };

  const handleResendOtp = () => {
    sendOtp();
  };

  const handleSendMessage = () => {
    triggerSendMessage(otpInput);
  };

  useEffect(() => {
    if (sendMessageData) {
      onNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessageData]);

  return (
    <>
      <div className="preview_wrapper">
        <p className="">
          Please preview your messages recipients details below
        </p>
        <div className="preview_larger_container">
          <p className="view_recipients">
            Click on a message to view recipients
          </p>
          <h6 className="text-danger">{state.notifyServer}</h6>
          <div className="preview_container">
            <div className="preview_items">
              <div>
                <img
                  style={{
                    width: '9.5rem',
                    height: '6.6rem',
                  }}
                  src={previewImg}
                  alt="preview-img"
                />
              </div>

              <div>
                <p
                  style={{
                    color: '#5C068C',
                    paddingTop: '1rem',
                    paddingLeft: '1rem',
                    fontFamily: 'segoe UI',
                    fontSize: '1rem',
                    fontWeight: '700',
                  }}
                >
                  {categoryName}
                </p>
                <div>
                  {previewMessages ? (
                    <div className="preview_message_item">
                      <p
                        style={{
                          color: '#575756',
                          paddingLeft: '1rem',
                          fontFamily: 'segoe UI',
                          fontStyle: 'normal',
                          fontSize: '0.875rem',
                          fontWeight: '400',
                        }}
                      >
                        {previewMessages}
                      </p>
                    </div>
                  ) : (
                    <div className="preview_message_item">
                      <p
                        style={{
                          color: '#575756',
                          paddingLeft: '1rem',
                          fontFamily: 'segoe UI',
                          fontStyle: 'normal',
                          fontSize: '0.875rem',
                          fontWeight: '400',
                        }}
                      >
                        Loading...
                      </p>
                    </div>
                  )}
                </div>

                <div className="preview_caret">
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={toggleFields}
                    src={Caret}
                    alt="caret"
                  />
                </div>
              </div>
              <hr />
            </div>

            {recipientData[0]?.receiverUsername &&
              showFields &&
              recipientData?.map((data, index) => (
                <div key={index} className="preview_fields">
                  <div className="preview_fields_items">
                    <label htmlFor="recipient name">
                      Recipient name or nickname
                    </label>
                    <input
                      className="preview_field"
                      type="text"
                      name="recipient name"
                      readOnly
                      defaultValue={data?.receiverUsername}
                    />
                  </div>
                  {state?.user?.messageMedium === 2 ? (
                    <div className="preview_fields_items">
                      <label htmlFor="recipient email">Recipient email</label>
                      <input
                        className="preview_field"
                        type="text"
                        readOnly
                        name="recipient email"
                        defaultValue={data?.emailAddress}
                      />
                    </div>
                  ) : (
                    <div className="preview_fields_items">
                      <label htmlFor="recipient phone">
                        Recipient Phone Number
                      </label>
                      <NumericFormat
                        allowLeadingZeros
                        className="preview_field"
                        type="tel"
                        readOnly
                        defaultValue={data?.phoneNumber}
                        placeholder="Enter phone number"
                        allowNegative={false}
                      />
                    </div>
                  )}
                </div>
              ))}

            {!recipientData[0]?.receiverUsername && showFields && (
              <div className="preview_fields">
                <div className="preview_fields_items">
                  <label htmlFor="recipient name">
                    Recipient name or nickname
                  </label>
                  <input
                    className="preview_field"
                    type="text"
                    name="recipient name"
                    readOnly
                    defaultValue={state?.user?.recipientName}
                  />
                </div>
                {state?.user?.messageMedium === 2 ? (
                  <div className="preview_fields_items">
                    <label htmlFor="recipient email">Recipient email</label>
                    <input
                      className="preview_field"
                      type="text"
                      readOnly
                      name="recipient email"
                      defaultValue={state?.user?.recipientEmail}
                    />
                  </div>
                ) : (
                  <div className="preview_fields_items">
                    <label htmlFor="recipient phone">
                      Recipient Phone Number
                    </label>
                    <NumericFormat
                      allowLeadingZeros
                      className="preview_field"
                      type="tel"
                      readOnly
                      defaultValue={state?.user?.recipientPhoneNumber}
                      placeholder="Enter phone number"
                      allowNegative={false}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="preview-disc-wrapper">
            <FontAwesomeIcon icon={faInfoCircle} />
            <p className="preview-disc-text">
              You are about to send this FCMB Shout Out message to{' '}
              <b>{state.user.recipientName}</b> and will be charged N10 for the
              service.
              <em className="fs-6">
                Please note, standard SMS cost still applies
              </em>
            </p>
          </div>

          {sendOtpData && (
            <div className="alert alert-success" role="alert">
              {sendOtpData}, Kindly Proceed
            </div>
          )}

          {sendOtpError && (
            <div className="alert alert-danger" role="alert">
              {sendOtpError}
            </div>
          )}

          <div className="isAccountOpenHeaderCol8">
            <Button variant="secondary" id="accSecondaryBtn" onClick={onBack}>
              BACK
            </Button>
            <span>
              <Button
                variant="primary"
                id="accPrimaryBtn"
                // onClick={() => onNext()}
                onClick={handleSendOtp}
                // disabled={Isloading}
                disabled={Isloading || sendOtpIsLoading}
              >
                {sendOtpIsLoading ? 'Loading...' : 'PROCEED'}
              </Button>
            </span>
          </div>

          {/* conditionally render modal for collecting user account details */}
        </div>
      </div>

      {isModalOpen && (
        <div>
          <Modal
            show={isModalOpen}
            // onHide={()=>do something}
            backdrop="static"
            keyboard={false}
            id="modal-wrapper"
          >
            <div id="gradient-modal-bar"></div>
            <Modal.Header
              className="rounded-0 mt-2"
              closeButton
              // onClick={() => modalClose()}
            ></Modal.Header>
            <Modal.Body>
              <div className="modal-body">
                <div className="form-fields">
                  <h5>Please provide your account number</h5>
                  <em className="text-danger">{state.notifyServer}</em>
                  <div className="kyc-input-wrapper mb-2">
                    <input
                      placeholder="Account Number"
                      value={inputs.accountNumber}
                      onChange={e => handleChange(e)}
                      type="text"
                      maxLength={10}
                      name="accountNumber"
                    />
                  </div>
                  <div className="kyc-input-wrapper check-wrapper mb-2">
                    <input
                      type="checkbox"
                      name="remember"
                      onChange={e => handleChange(e)}
                      checked={inputs.remember}
                    />
                    <span className="checkbox-cover">
                      <FontAwesomeIcon size="xs" icon={faCheck} />
                    </span>
                    <label className="pt-2">Save details for next time</label>
                  </div>
                </div>
                <div className="">
                  <Button
                    variant="secondary"
                    id="accSecondaryBtn"
                    // onClick={() => modalClose()}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="primary"
                    id="accPrimaryBtn"
                    onClick={() => handleController()}
                  >
                    PROCEED
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}

      {isOtpOpen && (
        <VerifyOtp
          isOtpOpen={isOtpOpen}
          setIsOtpOpen={setIsOtpOpen}
          state={state}
          sendOtpData={sendOtpData}
          otpInput={otpInput}
          handleOtpChange={handleOtpChange}
          triggerResendOtp={handleResendOtp}
          sendMessageData={sendMessageData}
          sendMessageError={sendMessageError}
          sendMessageIsLoading={sendMessageIsLoading}
          handleSendMessage={handleSendMessage}
        />
      )}

      {/* {isVerified && (
        <div>
          <Modal
            show={isVerified}
            backdrop="static"
            keyboard={false}
            id="isAccountOpenModal"
          >
            <div id="gradient-modal-bar"></div>
            <Modal.Header
              className="rounded-0 mt-2"
              closeButton
              // onClick={() => modalClose()}
              onClick={() => setIsOtpOpen(prev => !prev)}
            ></Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-4">
                  <img
                    src="/assets/svgs/sending.svg"
                    id="isAccImg"
                    alt="isAccountOpen"
                  />
                </div>
                <div class="col-md-8">
                  <div className="isAccountOpenHeaderCol8">
                    <div className="mt-4 mb-3">
                      <h2
                        style={{
                          color: '#222823',
                          fontSize: '1.125rem',
                          fontFamily: 'Segoe UI',
                          fontStyle: 'normal',
                          fontWeight: '700',
                        }}
                      >
                        Account Verified!!
                      </h2>

                      <p
                        style={{
                          color: '#575756',
                          fontSize: '1rem',
                          fontFamily: 'Segoe UI',
                          fontStyle: 'normal',
                          fontWeight: '400',
                        }}
                      >
                        Your account has been verified, proceed to send
                        shout-outs messages below
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <Button
                    variant="secondary"
                    id="accSecondaryBtn"
                    // onClick={() => isOtpOpen(false)}
                    onClick={() => setIsOtpOpen(prev => !prev)}
                  >
                    CANCEL
                  </Button>
                  <Button variant="primary" id="accPrimaryBtn" onClick={onNext}>
                    PROCEED
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )} */}
    </>
  );
};
