import { useContext, useState } from 'react';
import './UserDetails.css';
import { GlobalContext } from '../../Context/GlobalProvider';
import { useHistory } from 'react-router-dom/';

//Todo: create an enum for the message medium
const MessageMediumEnum = {
  sms: 1,
  email: 2,
};

export const UserDetails = ({ onNext, onChange, value, onBack }) => {
  const history = useHistory();
  //Get the context api
  const state = useContext(GlobalContext);

  //initialize state for the preferred nickname message
  const [preferredNickname, setPreferredNickname] = useState('');

  //initialize state for the selected message medium
  const [selectedMessageMedium, setSelectedMessageMedium] = useState(
    MessageMediumEnum.sms,
  );

  const [messageMediumError, setMessageMediumError] = useState('');

  const controller = e => {
    e.preventDefault();
    let isValid = true;
    //validate the preferred nickname
    if (preferredNickname === '') {
      state.notifyServerGlobal('Please enter your preferred name');
      return;
    }
    //validate the message medium
    if (!selectedMessageMedium) {
      setMessageMediumError('Please select your preferred message medium');
      isValid = false;
    }

    //if all is well, proceed to the next page
    else {
      state.notifyServerGlobal('');
      setMessageMediumError('');
      onNext();
    }

    state.notifyServerGlobal('');
    state.userInfo({
      preferredNickname: preferredNickname,
      preferredName: preferredNickname,
      messageMedium: value,
    });
    return isValid;
  };

  //create a handleChanege function to handle the change in the message medium
  const handleMessageMediumOptionChange = e => {
    //create a select value to hold the selected value
    const selectedValue = e.target.value;
    setSelectedMessageMedium(selectedValue);
  };

  const handleChange = e => {
    state.notifyServerGlobal('');
    setPreferredNickname(e.target.value.trim());
  };

  //create a handleSubmit function to handle the submission of the form
  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <div className="details">
      {/* create a form with the user details */}
      <p className="details_p">Please provide the following details</p>

      <form className="form" onSubmit={controller}>
        <div className="details_items">
          <label className="details_label" htmlFor="name">
            Your preferred name or nickname
          </label>
          <input
            value={preferredNickname}
            className="details_input"
            onChange={e => handleChange(e)}
            placeholder="Enter preferred name or nickname"
          />
          {state?.notifyServer && (
            <p style={{ color: 'red', fontSize: '16px' }}>
              {state?.notifyServer}
            </p>
          )}
        </div>

        <div className="details_items">
          <label className="details_label" htmlFor="medium">
            Your preferred message medium
          </label>
          <select value={value} className="details_input" onChange={onChange}>
            <option disabled={true} value="">
              Select
            </option>
            <option value={MessageMediumEnum.sms}>SMS</option>
            <option value={MessageMediumEnum.email}>Email</option>
          </select>
          <p className="details_error">{messageMediumError}</p>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div className="cursor-pointer">
            <p className="back_text" onClick={history.goBack}>
              BACK
            </p>
          </div>

          <button className="details_button">Next</button>
        </div>
      </form>
    </div>
  );
};
