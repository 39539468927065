import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitterSquare, faInstagram, faLinkedin, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return (
        <div>
            <footer className="bg-light text-center text-lg-start">
                <div className="text-right p-1" style={{ "background": "rgba(93, 33, 134, 1)" }}>

                    <a className="text-white" href="/#">My Bank and I</a>
                </div>
                {/* footer */}
                <div className="text-center p-3">

                    <div className="text-primary">
                        <a href="https://web.facebook.com/FCMBMyBank">
                            <FontAwesomeIcon id="footer-icon" icon={faFacebook} />
                        </a>

                        <a href="https://twitter.com/MyFCMB">
                        <FontAwesomeIcon id="footer-icon" icon={faTwitterSquare} />
                        </a>

                        <a href="https://www.linkedin.com/company/first-city-monument-bank-ltd">
                        <FontAwesomeIcon id="footer-icon" icon={faLinkedin} />
                        </a>

                        <a href="https://www.youtube.com/user/fcmbplc">
                        <FontAwesomeIcon id="footer-icon" icon={faYoutube} />
                        </a>

                        <a href="https://www.instagram.com/myfcmb/ ">
                        <FontAwesomeIcon id="footer-icon" icon={faInstagram} />
                        </a>
                        
                        <a href="https://wa.me/2349099999814/">
                        <FontAwesomeIcon id="footer-icon" icon={faWhatsapp} />
                        </a>
                    </div>
                </div>
                {/* Footer ends */}
            </footer>
        </div>
    );
}

export default Footer;