import React from "react";

function HowItWorks() {
    return (
        <div>
            <section className="bg-light text-center text-lg-start" id="howitworks" >
                <h1 id="howitwor" style={{ color: "#5D2186", fontSize: "36px" }}>How it Works</h1>
                <div className="row justify-content-center" id="howitworksrow" >
                    <div className="col-4 d-flex justify-content-start">

                        <div className="card" style={{ "width": "100%", border: "none" }}>
                            <div className="card-body">
                                <img src="/ima/how-server.SVG" id="logo" alt="howserver" />                            
                                </div>
                        </div>

                    </div>

                    <span className="vertical-line"><img src="/ima/how-one.SVG" id="howone" alt="logo" /></span>


                    <div className="col-4">

                        <div className="card" style={{ "width": "100%", border: "none" }}>
                            <div className="card-body">
                                <h5>Select the appropriate category</h5>
                                <p className="card-text">	Browse through our extensive shout out categories and select the most appropriate one.
</p>
                            </div>
                        </div>

                    </div>

                </div>

         <div id="noOnMobile">

                <div className="row justify-content-center" id="howitworksrow" >
                    <div className="col-4 d-flex justify-content-start">

                        <div className="card" style={{ "width": "100%", border: "none" }}>
                            <div className="card-body">
                                <h5>Choose your preferred SMS</h5>
                                <p className="card-text">Select your preferred SMS from the list of existing pre-filled messages.</p> </div>
                        </div>

                    </div>

                    <span className="vertical-line"><img src="/ima/how-two.SVG" id="howone" alt="logo" /></span>


                    <div className="col-4">
                        <div className="card" style={{ "width": "100%", border: "none" }}>
                            <div className="card-body">
                                <img src="/ima/how-love.SVG" id="logo" alt="howserver" />
                                <img src="/ima/speech_bubble_1.SVG" id="howitworkslogo" alt="howserver" />                            </div>
                        </div>

                    </div>
                </div>
                </div>
{/**
 * This is for mobile display, it will be hidden on desktop
 */}


<div className="row justify-content-center" id="howitworksrowMobile" >
                    <div className="col-4 d-flex justify-content-start">

                        <div className="card" style={{ "width": "100%", border: "none" }}>
                            <div className="card-body">
                                <img src="/ima/how-love.SVG" id="logo" alt="howserver" />
                                <img src="/ima/speech_bubble_1.SVG" id="howitworkslogo" alt="howserver"/>
                            </div>
                        </div>
                    </div>
                    <span className="vertical-line"><img src="/ima/how-three.SVG" id="howone" alt="logo" />
                        <img src="/ima/3.SVG" id="how3" alt="logo" />
                    </span>

                    <div className="col-4">

                        <div className="card" style={{ "width": "100%", border: "none" }}>
                            <div className="card-body">
                                <h5>Choose your preferred SMS</h5>
                                <p className="card-text">Select your preferred SMS from the list of existing pre-filled messages.</p>
                            </div>
                        </div>

                    </div>
                </div>

 {/**
  * This is the end of the mobile
  */}




                <div className="row justify-content-center" id="howitworksrow" >
                    <div className="col-4 d-flex justify-content-start">

                        <div className="card" style={{ "width": "100%", border: "none" }}>
                            <div className="card-body">
                                <img src="/ima/how-profile.SVG" id="logo" alt="howserver" />
                                <img src="/ima/how-details.SVG" id="howitworkslogo" alt="howserver" />
                            </div>
                        </div>

                    </div>
                    <span className="vertical-line"><img src="/ima/how-three.SVG" id="howone" alt="logo" />
                        <img src="/ima/3.SVG" id="how3" alt="logo" />
                    </span>

                    <div className="col-4">

                        <div className="card" style={{ "width": "100%", border: "none" }}>
                            <div className="card-body">
                                <h5>Fill in details</h5>
                                <p className="card-text">Input the appropraite details such as your preffered name and recipient name.
</p>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        </div>
    );
}

export default HowItWorks;